<template>
  <div class="audio">
    <van-nav-bar :title="this.album.title" left-text="返回" left-arrow @click-left="onBack">
    </van-nav-bar>
    <div class="album">
      <van-image
        :src="album.cover"
      />
      <div class="album-title">{{ album.title }}</div>
      <div class="album-desc">{{ album.desc }}</div>
    </div>
    <div class="van-hairline--bottom"></div>
    <aplayer autoplay 
      class="raido" 
      :list="playList"
      :music="playInfo"
    />
  </div>
</template>
<script>
import Aplayer from 'vue-aplayer'
export default {
  data() {
    return {
      title: '電台',
      aid: 0,
      cid: 0,
      id:0,
      playList: [],
      playInfo:{
        title: '阿彌陀佛',
        artist: '妙音',
        src: '//hk-qiniu.ciguang.tv/uploads/radio/2020/12/R20201204-01_s.mp3',
        pic: '//hk-qiniu.ciguang.tv/uploads/image/picture/2020/12/b42fd843fb54d6f81f4d7651d19f47ed.jpg?imageMogr2/auto-orient/thumbnail/700x700>/quality/100|imageslim'
      },
      listMaxHeight:'200px',
      album: {},
    }
  },
  methods: {
    getPlayList(){
      let url = this.$tools.api_cg + 'radio/infolist' + this.$tools.client_cg + '&album_id=' + this.aid;
      this.axios.get(url)
      .then(response => {
        if(response.data.code==1){
          this.playList = this.$tools.fromatRadio(response.data.data.rows);   
          this.playInfo = this.playList[0];
          if(this.id){
            for (let i = 0; i < this.playList.length; i++) {
              if(this.id == this.playList[i].id){
                this.playInfo = this.playList[i];
              }
            }
          }
          this.album = response.data.data.album;
        }      
      }) 
    },  
    //返回
    onBack(){
      this.$router.push({ path: `/radio/${this.cid}` }) 
    },
  },
  beforeDestroy() {

  },
  mounted(){

  },
  created() {
    this.aid = this.$route.params.aid;
    this.cid = this.$route.params.cid;
    this.id = this.$route.params.id;
    this.getPlayList();
  },
  components: {
        Aplayer
  },
  metaInfo () {
    return {
      title: this.playInfo.title 
    }
  },
};
</script>
<style  scoped>

.raido{
  margin:10px 10px 60px 10px;
}

.album{
  padding:5px 20px;
}

.album-title{
  height:40px;
  line-height: 40px;
}
.album-desc{
  font-size: 12px;
  line-height: 20px;
}
</style>