<template>
  <div class="videoplayer">
    <van-nav-bar :title="playTitle" left-text="返回" left-arrow @click-left="onBack">

    </van-nav-bar>
    <div id="mse"></div>
    <van-tabs v-model="active">
      <van-tab title="頻道">        
        <van-grid :column-num="3" class="channel" icon-size="40">
          <van-grid-item  
          v-for="item in playChannelList" 
          :key="item.id"
          :icon="item.icon" 
          :text="item.title" 
          :class="item.class"
          @click="changeReload(item.id)"/>
        </van-grid>
      </van-tab>
      <van-tab title="課表">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text=""
          @load="onLoadKebiao"
        >
          <div 
            class="van-clearfix kb-item"
            :class="item.class"
            v-for="item in playList" 
            :key="item.id"
            @click="rePlay(item.id)"
          >
            <div class="kb-ico"><van-icon :name="item.icon" /></div>
            <div class="kb-time">{{ item.time }}</div>
            <div class="kb-title" >{{ item.title }}</div>
          </div>
        </van-list>

      </van-tab>
    </van-tabs>

  </div>
</template>

<script>
import 'xgplayer';
import HlsJsPlayer from 'xgplayer-hls.js';
export default {
  data() {
    return {
      list: [],
      info: [],
      iPlayer: null,
      active:0,
      kblist:[],
      loading: false,
      finished: false,
      playTitle: '淨宗學院',
      playType: 'live',
      playUrl: '',
      playSeek: 0,
      playCut: 0,
      playCutUrl:'',
      playIndex: -1,
      replayIndex: -1,
      playList: [],
      playChannelList: [],
      playLive: true,
      playControls:['time','progress'],
      iTimer :null,
      frist:true,
    };
  },
  methods: {
    initPlayer() {
      this.destroyPlayer();
      this.iPlayer = new HlsJsPlayer({
        id: 'mse',
        url: this.playUrl,
        fluid: true,
        autoplay: true,
        playsinline: true,
        lastPlayTime: this.playSeek,
        lastPlayTimeHideDelay: 0.1,
        isLive: true,
        debug: {
          host:'192.168.0.101',
          port: 8080
        },
        ignores: this.playControls,
      });
      this.iPlayer.on('ready',()=>{
        console.log('ready')
      })
      this.iPlayer.on('complete',()=>{
        if(this.frist){
          this.toPlay();
        }
        console.log('complete')
      })
      this.iPlayer.on('ended',()=>{
        this.playCut = 1;
        this.toPlay();
        console.log('ended')
      })
      this.iPlayer.on('bufferedChange',()=>{
        console.log('bufferedChange')
      })
    },
    //获取频道列表
    getChannel(id){
      this.axios.get(this.$tools.api + '/live/getchannel' )
      .then(response => {
        if(response.data.code==1){
          this.list = response.data.rows;
          if(id){
            this.changeChannel(id);
          }else{
            this.changeChannel(1);
          }
          this.timer();
        }
      }) 
    },
    //切换频道
    changeReload(index){
      let timestamp = Math.floor(Date.parse(new Date()) / 1000);
      window.location.href =  `/alive/${index}/${timestamp}`;
    },
    changeChannel(index){
      //this.destroyPlayer();
      this.info = this.list[index-1];
      this.playChannelList = [];
      for (var i=0; i<this.list.length; i++){
        let channel = [];
        channel.id = this.list[i].id;
        channel.icon = this.list[i].logo;
        channel.title = this.list[i].title;
        channel.kbid = this.list[i].curriculum_id;
        this.playCutUrl = this.list[i].cut_url;
        if(index == (i+1)){
          channel.class = 'is-channel';
        }
        this.playChannelList.push(channel);
      }    
      this.playType = this.info.type;
      this.playTitle = this.info.title;
      
      if(this.playType == 'live'){
        this.playUrl = this.info.live_url.replace("http:","");
      }
      this.playLive = true;
      //this.$router.push({ path: `/live/${index}` })
      this.onLoadKebiao();
    },
    //销毁播放器
    destroyPlayer(){    
      if(this.iPlayer != null){
        this.iPlayer.destroy();
        this.frist = true;
      }       
    },
    //加载课表
    onLoadKebiao() {
      this.axios.get(this.$tools.api + '/live/getkebiao?date=0&id=' + this.info.curriculum_id )
      .then(response => {
        if(response.data.code==1){
          this.kblist = response.data.data;
          this.updateKb();
        }
      }) 
      this.finished = true;
    },
    //更新课表
    updateKb(){
      console.log('当前频道播放状态 ---' +this.playLive); 
      this.playList = [];
      for (var i=0; i<this.kblist.length; i++){
        let item = this.kblist[i];
        let playList = [];
        if (this.$tools.timeCha(item.starttime_ux) > 0 && this.$tools.timeCha(item.endtime_ux) < 0) {
          playList.icon = "play-circle-o";
          this.playIndex = i; 
          if(this.playLive){
            playList.class= "is-active"
            if (this.playType != 'live') {
              this.playUrl = item.url;
              this.playSeek = this.$tools.timeCha(item.starttime_ux) / 1000;
              console.log(this.playSeek);
              this.playCut = 0;
            }
          }

        } else {
          if(this.$tools.timeCha(item.starttime_ux) > 0) {
            playList.icon = "passed";
          }else{
            playList.icon = "clock-o";
          }
        }
        //如果是回放
        if(!this.playLive && this.replayIndex == i){
          playList.class= "is-active";
          this.playUrl = item.url;
        }

        playList.id = i;
        playList.time = item.starttime;
        playList.title = item.title;
        this.playList.push(playList);
      }  
      if(this.iPlayer == null){
        this.initPlayer();
        console.log('--- 首次打开，创建播放器 ---' );
      }else{
        console.log('--- 切换频道 ---' );
        this.toPlay();
      }
    },
    //检查播放
    checkPlay(){
      console.log('正在播放 ---playSeek:' + this.playSeek + '--- url:'+ this.playUrl);
      if(this.frist){
        this.frist = false;
        //this.toPlay();
      }
      for (var i=0; i<this.kblist.length; i++){
        if (this.$tools.timeCha(this.kblist[i].starttime_ux) > 0 && this.$tools.timeCha(this.kblist[i].endtime_ux) < 0) {
          if(this.playIndex != i && this.playLive){
            this.updateKb();
            console.log('节目更新 ---' );
          }
        }     
      }  
    },
    timer() {    
      this.iTimer = setInterval(()=>{
         this.checkPlay();
      },5000)
    },
    toPlay(){
      if(this.iPlayer != null){
        if(this.playCut){
          this.playUrl = this.playCutUrl;
          this.playSeek = 0;
        }
        this.iPlayer.src = this.playUrl;
        this.iPlayer.currentTime = this.playSeek;
        this.iPlayer.reload();
        console.log('开始播放 ---' +this.playUrl); 
      }
    },
    //回放
    rePlay(id){
      //this.destroyPlayer();
      if(this.playIndex == id){
        this.playLive = true;
        //this.iPlayer.controls = ['time'];
      }else{
        this.playLive = false;
        this.replayIndex = id;
        this.playSeek = 0; 
      }
      this.iPlayer.isLive =  this.playLive;
      this.updateKb();
    },
    //返回
    onBack(){
      this.$router.push({ path: `/` })
    },
  },
  mounted() {
      console.log('定时器加载 ---'); 
  },
  beforeDestroy() {
      clearInterval(this.iTimer);
      console.log('定时器清除 ---');
      if(this.iPlayer){
        this.iPlayer.pause();
        this.iPlayer.off('ready',function(){});
        this.iPlayer.off('complete',function(){});
        this.iPlayer.off('ended',function(){});
        this.iPlayer.off('bufferedChange',function(){});
        this.iPlayer.destroy(true)
        this.iPlayer = null;
      }
      location.reload();
  },
  created() {
    this.frist = true;
    this.getChannel(this.$route.params.id);
  },
  metaInfo () {
    return {
      title: this.playTitle + '-直播'
    }
  },
};
</script>

<style  scoped>
.channel{
  margin:10px 10px;
  border-left: 1px solid #ebedf0;
}
.van-list{
  overflow: auto;
  margin:10px 0px;
  padding:0 10px;
  height:300px;
}
.kb-item{
  height:40px;
  line-height: 40px;
  border-bottom: 1px solid #ebedf0;
}
.kb-ico{
  float: left;
  padding-left: 5px;
  font-size: 16px;
}

.kb-time{
  width:80px;
  float: left;
  text-align: center;
  font-size: 14px;
  color: #414141;
}
.kb-title{
  float: left;
  font-size: 14px;
  color: #414141;
}

.is-active{
  background-color:#f8ebbf;
}

.is-channel{
  background-color:#f8ebbf;
}
.xgplayer-memoryplay{
  display:none!important;
}
</style>