<script>
import axios from "axios";
import Cookies from 'js-cookie'
const api = "//www.jingzong.org/web";
const sp_api = "https://www.jingzong.net/web";
const media = "https://video.cibei.org/";
// const media = "https://m3u8.yyii.info/";
const download_url = "https://media.cibei.org/";
const api_cg = "https://api.ciguang.org/api/v5/";
const client_cg = "?client=android&v=5";
const static_url = "//www.jingzong.org/static/";
const text_url = '//i.jingzong.org/amtbtv/docs/';
const server_url =  '//www.jingzong.org/uploadfile/';
const qiniu_url =  '//i.yyii.info/';
const image_320 = '?imageMogr2/thumbnail/320x180>/format/jpg/quality/70|imageslim';
const image_640 = '?imageMogr2/thumbnail/1280x720>/format/jpg/quality/100|imageslim';
const regString = /[a-zA-Z]+/;
const textLanguage = '/txt/zh_TW'; 
//時間比較
function getTimeDiff(time) {
  return Date.parse(new Date()) / 1000 - time;
}

//依據編號重構音頻地址
function getVideoNumUrl(host, num, ext) {
  var url = "";
  if (num != null) {
    var _num = num.split("-");
    url = host +  _num[0] + "/" + _num[0] + "-" + _num[1] + "/";
    if (ext == "m3u8") {
      url = url + num + "/";
    }
    url = url + num + "." + ext;
  }
  return url;
}

// 返回墨寶金石縮略圖地址
// $num 圖片編號
// 例子mbjs/small/A01/A01-117.gif
function getMobaoUrl(num, type, ext)
{
    let arr = num.split("-");
    let url = server_url + "mbjs/" + type + "/";
    url += arr[0] + "/" + num + "." + ext;
    return url;
}

// 例子mbjs/small/A01/A01-117.gif
function getPictureUrl(cover, ext)
{
    let url = qiniu_url + cover + ext;
    return url;
}

//判断时间差
function timeCha(time){
  var timestamp = Date.parse(new Date()); 
  return timestamp - time;
}

//时间戳
function getTimeStamp(){
  var timestamp = Date.parse(new Date());
  return timestamp;
}

//获取文字档
const getTxt = async (num) => {
  let content;
  if(regString.test(num)){
      let url = api + '/video/itext/num/' + num;
      await axios.get(url)
      .then(response => {
          content = response.data;
          
      })      
  }else{
      let url = text_url + num + textLanguage ;
      console.log(url); 
      await axios.get(url)
      .then(response => {
        if(response.data.txt.txt==1){
          content = fromatText(response.data.txt.content);
        }
      }) 
  }
  return content;
};

//文字格式
function fromatText(text){
  let content = "<p>" + text.replace(/[\n]/g,"</p><p>　　") + "</p>";
  return content;
}


function fromatPicture(list, field){
  let nlist = [];
  for (let i = 0; i < list.length; i++) {
      let url = '';
      if(field == "cover"){
        url = qiniu_url + list[i].cover  + image_640;
      }else{
        url = list[i].title;
      }
      nlist.push(url); 
  }  
  return nlist; 
}

function fromatMobao(list, field){
  let nlist = [];
  for (let i = 0; i < list.length; i++) {
      let url = '';
      if(field == "cover"){
        let arr = list[i].cover.split("-");
        url = server_url + "mbjs/big/" + arr[0] + "/" + list[i].cover + ".jpg";
      }else{
        url = list[i].title;
      }
      nlist.push(url); 
  }  
  return nlist; 
}

function fromatRadio(list){
  let nlist = [];
  for (let i = 0; i < list.length; i++) {
      let item = {};
      item.title = list[i].title;
      item.src = list[i].music_url;
      item.pic = list[i].square;
      item.artist = list[i].author;
      nlist.push(item); 
  }  
  return nlist; 
}

function fromatAudio(list){
  let nlist = [];
  for (let i = 0; i < list.length; i++) {
      let item = {};
      item.src = getVideoNumUrl(media + 'mp3/' , list[i].num, 'mp3');
      item.name = list[i].title;
      item.poster = '';
      item.vid = i;
      nlist.push(item); 
  }  
  return nlist; 
}

function fromatUrl(url) {
  const str = url.split("/");
  let list = [];
  list['model'] = str[1];
  list['controller'] = str[2];
  list['id'] = str[3];
  list['cid'] = str[4];
  return list;
}

function fromatVideoUrl(url) {
  const str = url.split("/");
  let list = [];
  list['model'] = str[1];
  list['controller'] = str[2];
  list['cid'] = str[4];
  list['cnum'] = str[6];
  list['id'] = str[10];
  return list;
}

/**
 * 字符串替换
 * @param  {string} str    要被替换的字符串
 * @param  {string} substr 要替换的字符串
 * @param  {string} newstr 用于替换的字符串
 * @return {string}        替换后的新字符串
 */
function replace_text(str, substr, newstr) {
  substr = substr.replace(/[.\\[\]{}()|^$?*+]/g, "\\$&"); // 转义字符串中的元字符
  var re = new RegExp(substr, "g"); // 生成正则
  return str.replace(re, newstr);
}

//格式化图文内容
function fromatContent(html){
   html = replace_text(html, 'width: 680px;', 'width: 100%;')
   return html;
}

//根据id返回栏目名
function getCategoryTitle(list,id){
  for (let i = 0; i < list.length; i++) {
    if(list[i].id== id){
      return list[i].title;
    }
  }
  return '';
}
function getDevice(){

    var u = navigator.userAgent;
    let device = '';
      //if(u.indexOf('AppleWebKit') > -1){
      //  device = 'AppleWebKit';
      //}  
      //if(u.indexOf("chrome") > -1){
      // device = 'chrome';
      //}
      //if(u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1){
      //  device = 'Gecko';
      //}
      //if(!!u.match(/AppleWebKit.*Mobile.*/) || !!u.match(/AppleWebKit/)){
      //  device = 'mobile';
      //}
      if(u.indexOf('Android') > -1 || u.indexOf('Linux') > -1){
        device = 'Android';
      }
      if(u.indexOf('iPhone') > -1){
        device = 'iPhone';
      }
      if(u.indexOf('iPad') > -1){
        device = 'iPad';
      }
      if(u.indexOf('Safari') == -1){
         device = 'Safari';
      }
  console.log(device);
  return device;
}

function IsWeixinOrAlipay() {
    var ua = window.navigator.userAgent.toLowerCase();
    //判断是不是微信
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return 13;
    }
    //判断是不是支付宝
    if (ua.match(/AlipayClient/i) == 'alipayclient') {
        return 12;
    }
    //哪个都不是
    return 0;
}
        // 判断是在安卓还是ios打开
function isAndriodOrIos() {
    var ua = window.navigator.userAgent.toLowerCase();
    if(ua.match(/iphone/i) == 'iphone'){
        return 1;
    }
    return 0;
}
// 提交打开
const postData = async (url) => {
    let token = '';
    let data = [];
    //Cookies.remove('ciguang');
    if(Cookies.get('ciguang') != '' && Cookies.get('ciguang') != null){
      token =Cookies.get('ciguang');
    }
    url = sp_api +  url;
    await axios.get(url + '&token=' + token)
    .then(response =>{
        if(token == ''){
          Cookies.set('ciguang', response.data.token, { expires: 3600 });
        }
        data = response.data;
        console.log(data.token);
    })
    return data;
}

const getData = async (url) => {
    let data = [];
    if(Cookies.get('ciguang') != '' && Cookies.get('ciguang') != null){
      let token = Cookies.get('ciguang');
      url = sp_api +  url;
      await axios.get(url + '?token=' + token)
      .then(response =>{
          data = response.data;
      })
    }
    return data;
}
export default {
  api,
  text_url,
  static_url,
  download_url,
  api_cg,
  client_cg,
  sp_api,
  media,
  getTimeDiff,
  timeCha,
  getTimeStamp,
  getVideoNumUrl,
  getMobaoUrl,
  getPictureUrl,
  fromatText,
  fromatPicture,
  fromatMobao,
  fromatRadio,
  fromatAudio,
  fromatUrl,
  fromatVideoUrl,
  fromatContent,
  getTxt,
  image_320,
  getDevice,
  getCategoryTitle,
  IsWeixinOrAlipay,
  isAndriodOrIos,
  postData,
  getData,
};
</script>