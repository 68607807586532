<template>
  <div class="view">
    <van-popup v-model="isShow"
      class="overlay-bg"
      position="bottom"
      :style="{ height: '100%' }">
      <van-image
          :src="downBg"
          :height="imgHeight"
          fit="contain"
        />
      </van-popup>
    <div class="c-content tb-20"> 
          <van-image
          radius = "15"
          width = "120"
          :src="icon"
        />
        <div class="icon-title">淨公道影</div>
        <van-image
          width = "150"
          @click="down"
          src="https://i.yyii.info/static/ciguang_v1/image/down_android.png"
        />
    </div>
    <div class="c-content"> 
      <div class="c-text">  
        「淨公道影」軟件截圖
      </div>     
      <div class="van-hairline--top"></div>
        <van-image
        class="c-image"
          v-for="(item, index) in list" 
          :key="index"
          :src="item"
          fit="contain"
        />
    </div>
    <div class="c-content padding-10"> 
       <van-image
          width = "150"
          @click="down"
          src="https://i.yyii.info/static/ciguang_v1/image/down_android.png"
        />
    </div>
  </div>
</template>
<script>


export default {
  data() {
    return {
      title: '净公道影APP下载',
      info: [],
      list: [
        'https://i.yyii.info/uploads/image/announce/20220828/7ebb48e82cb5deca183ad0d69ae410d0.png',
        'https://i.yyii.info/uploads/image/announce/20220828/9a42d5d1e046e61db9a7e2531449e58d.png',
        'https://i.yyii.info/uploads/image/announce/20220828/6cb7c81f83eb7821e4ca1a512087d088.png',
      ],
      icon:'https://x.ciguang.tv/static/v2/images/launcher_icon.png',
      isShow: false,
      isWx: false,
      loading: true,
      downBg: "https://i.yyii.info/uploads/image/announce/20220827/6957afd2c1b7ee0246af5ee61a3610b4.png",
      imgHeight: document.documentElement.clientHeight,
      download:"https://u.jingzong.org/u/dy",
      iosUrl : "https://apps.apple.com/cn/app/dsoon/id1641725232",
    }
  },
  methods: {
    //返回
    onBack(){
      this.$router.push({ path: `/i` })  
    },
    onLoad(){
      console.log('---');
    },
    down(){
      window.location.href =
              this.download;
      },
    goTo(){         
        if (this.$tools.getDevice() == 'iPhone') {
            window.location.href = this.iosUrl;
        }
    }
  },
  beforeDestroy() {

  },
  mounted(){
  },
  created() {
    if(this.$tools.IsWeixinOrAlipay()==13){
      this.isWx = true;
      this.isShow = true;
    }else{
      this.goTo();
    }
  },
  metaInfo () {
    return {
      title: this.title
    }
  },
};
</script>
<style  scoped>
.view{
    background-color: #f7f8fa;
    margin-bottom: 70px;  
}
.c-content{
  padding:5px 5px;
  text-align: center;
}
.c-image{
    padding:15px 15px;
    margin: 10px 20px;;
    background-color: #fff;
}
.tb-20{
  padding:20px 0;
}
.padding-10{
  padding:10px 10px;
}
.overlay-bg{
  background-color: #2e2e2e;
}
.c-text{
  padding:10px 0;
  font-size: 22px;
  text-align: center;
}
.icon {
    border-radius: 25px;
}
.icon-title{
  font-size: 22px;
  padding:5px 0 20px 0;
}
</style>