<template>
  <div class="video">
    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onBack">
      <template #right>
        <van-icon name="search" size="18" @click="onSearch"/>
      </template>
    </van-nav-bar>
    <van-tabs @click="getAlbum()" v-model="cid">
      <van-tab 
      v-for="(item,index) in clist" 
      :key="index"
      :title="item.title"
      :name="item.id"
      >
      <van-row class="v-list" gutter="15">
          <van-col span="12"
          v-for="(item, index) in alist" 
          :key="index"
          class="v-item"
          >
            <a @click="goto(index)">
            <img v-lazy="getImg320(item.cover)" alt="" class="v-item-img">
            <div class="v-item-desc">
              <div class="v-item-title van-multi-ellipsis--l2">
                {{ item.title }}
              </div>
              <div class="v-item-date">共 {{ item.total }} 集</div>
            </div>
          </a>
        </van-col>
        </van-row>
      </van-tab>
    </van-tabs>
   
  </div>
</template>
<script>

export default {
  data() {
    return {
      title: '全集',
      clist: [],
      alist: [],
      cid: 0,
      activeId: 0,
      page:0,
      last_page:1,
      metaTitle: '',
      isLoad: false,
    }
  },
  methods: {
    //获取欄目列表
    getCategory(id){
      this.axios.get(this.$tools.api + '/jiangyan/getcategory')
      .then(response => {
        if(response.data.code==1){
          this.clist = response.data.rows;
          console.log(this.clist)
          if(id==null){
            this.cid = this.clist[0].id;
          }else{
            this.cid = id;
          }
          this.getAlbum();
        }
      }) 
    },
    //获取專輯列表
    getAlbum(){
      if(this.cid != this.activeId){
        this.page = 0;
        this.last_page =1;
        this.$router.push({ path: `/jiangyan/${this.cid}` });
      }
      if(this.page < this.last_page){
        this.page ++;
        let url = this.$tools.api + '/jiangyan/album?cid='+this.cid+'&page=' + this.page;
        if(this.cid != this.activeId){
          this.alist = [];
          this.activeId = this.cid;
        }
        
        this.axios.get(url)
        .then(response => {
            let lis = response.data.data;
            for (let i = 0; i < lis.length; i++) {
                this.alist.push(lis[i]); 
            }            
            if(this.page==1){
              this.last_page = response.data.last_page;   
            }   
            this.isLoad = false; 
        }) 
      }
      this.metaTitle = this.$tools.getCategoryTitle(this.clist, this.cid);
    },
    goto(index){
      let info = this.alist[index];
      this.$router.push({ path: `/player/c/${info.id}/${info.num}` }) // -> /user/123
    },    
    //返回
    onBack(){
      this.$router.push({ path: `/` }) // -> /user/123
    },
    // 滚动操作
    handleScroll() {
      var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
      var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight; //变量scrollHeight是滚动条的总高度
      var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight >= (scrollHeight-100) && !this.isLoad) {
          this.isLoad = true;
          this.getAlbum();
          console.log('scrollTop:'+scrollTop+'|windowHeight:'+windowHeight+'|scrollHeight:'+scrollHeight);
          return false;
      }
    },
    onSearch(){
      this.$router.push({ path: `/search/jiangyan` }) // -> /user/123
    },
    getImg320(url){
      return url + this.$tools.image_320;
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll",this.handleScroll,true)
  },
  mounted(){
    // 监听滚动
    window.addEventListener('scroll',this.handleScroll,true)
  },
  created() {
    this.getCategory(this.$route.params.id);
  },
  metaInfo () {
    return {
      title: this.metaTitle + '-講演全集'
    }
  },
};
</script>
<style  scoped>

</style>