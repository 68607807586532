<template>
  <div class="video">
    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onBack">
      <template #right>
        <van-icon name="search" size="18" @click="onSearch"/>
      </template>
    </van-nav-bar>
    <van-tabs @click="getAlbum()" v-model="cid">
      <van-tab 
      v-for="(item,index) in clist" 
      :key="index"
      :title="item.title"
      :name="item.id"
      >
      <van-row class="v-list" gutter="15">
          <van-col span="12"
          v-for="(item, index) in alist" 
          :key="index"
          class="v-item"
          >
            <a @click="goto(index)">
            <img v-lazy="item.cover" alt="" class="v-item-img">
            <div class="v-item-desc">
              <div class="v-item-title van-multi-ellipsis--l2">
                {{ item.title }}
              </div>
              <div class="v-item-date">{{ item.date }}</div>
            </div>
          </a>
        </van-col>
        </van-row>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
export default {
  data() {
    return {
      title: '電台',
      clist: [],
      alist: [],
      cid: 0,
      aid: 0,
      activeId: 0,
      albumUrl:'',
      metaTitle: '',
    }
  },
  methods: {
    //获取欄目列表
    getCategory(id){
      this.axios.get(this.$tools.api_cg + 'radio/category' + this.$tools.client_cg)
      .then(response => {
        if(response.data.code==1){
          this.clist = response.data.data.rows;
          if(id == null){
            this.cid = this.clist[0].id;
          }else{
            this.cid = parseInt(id);
          }
          this.getAlbum();
        }
      }) 
    },
    //获取專輯列表
    getAlbum(){
      let url = this.$tools.api_cg + 'radio/album' + this.$tools.client_cg + '&catid=' + this.cid;
      if(this.cid != this.activeId){
        this.alist = [];
        this.activeId = this.cid;
        this.$router.push({ path: `/radio/${this.cid}` }) 
      }
      this.axios.get(url)
      .then(response => {
          this.alist = response.data.data.rows;          
      }) 
      this.metaTitle = this.$tools.getCategoryTitle(this.clist, this.cid);
    },

    goto(index){
      this.aid = this.alist[index].album_id;
      this.$router.push({ path: `/aplayer/${this.cid}/${this.aid}` }) 
    },    
    //返回
    onBack(){
      this.$router.push({ path: `/` }) // -> /user/123
    },
    onSearch(){
      console.log('搜索')
      this.$router.push({ path: `/search/radio` }) // -> /user/123
    }
  },
  beforeDestroy() {

  },
  mounted(){

  },
  created() {
    this.getCategory(this.$route.params.id);
  },
  metaInfo () {
    return {
      title: this.metaTitle + '-' + this.title 
    }
  },
};
</script>
<style  scoped>

</style>