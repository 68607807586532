<template>
  <div class="specail-live">
    <vue-aliplayer-v2 :source="source" ref="VueAliplayerV2" :options="options" @play="handlePlay" @ended="handleEnded" @canplay="handleCanplay"/>
    <van-tabs v-model="active">
      <van-tab title="lịch trình">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text=""
          @load="onLoadKebiao"
        >
          <div 
            class="van-clearfix kb-item"
            :class="item.class"
            v-for="item in playList" 
            :key="item.id"
          >
            <div class="kb-ico"><van-icon :name="item.icon" /></div>
            <div class="kb-time">{{ item.time }}</div>
            <div class="kb-title" >{{ item.title }}</div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="Đề xuất">
        <div class="tb-10">
          <div class="tb-content">
            <p             
            v-for="item in contentList" 
            :key="item.id">
            {{ item.vt }}
            </p>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
//推荐第一种(仅v1.2.3)及以上的版本可用
import VueAliplayerV2 from "vue-aliplayer-v2";
export default {
  components: {
    VueAliplayerV2
  },
  data() {
    return {
      kblist:[],
      kblist_text:[{
                "id": "04",
                "type": "播出",
                "num": "PC-019-0002",
                "starttime": "08:00",
                "starttime_ux": "1646632210000",
                "endtime": "13:10",
                "endtime_ux": "1646639590000",
                "title": "念佛 第一支香",
                "url": this.$tools.media + "360p/PC/PC-019/PC-019-0001/PC-019-0001.m3u8"
            },

      ],
      loading: false,
      finished: false,
      active:0,
      playTitle: '同步念佛',
      playSeek: 0,
      playCut: 0,
      playCutUrl: this.$tools.media + '360p/PC/PC-019/PC-019-0002/PC-019-0002.m3u8',
      playIndex: -1,
      playList: [],
      playLive: false,
      playUrl: '',
      CurriculumId : 617,
      iTimer :null,
      fullHeight: document.documentElement.clientHeight - 100,
      options: {
        autoplay :true,
        isLive: false,   //切换为直播流的时候必填
        height: document.documentElement.clientHeight - 100 +'px',
        skinLayout: [
          {name: "bigPlayButton", align: "cc"},
          {name: "H5Loading", align: "cc"},
          {
            name: "controlBar", align: "blabs", x: 0, y: 0,
            children: [
                {name: "playButton", align: "tl", x: 15, y: 12},
                {name:"fullScreenButton", align:"tr", x:10, y: 10},
                {name:"volume", align:"tr", x:5, y:10}
              ]
          }
        ]
      },
      source: '',
      //cover: 'www.jingzong.org/static/sandbox_v2/img/pureland/share-phone.png',
      show: true,
      isShowMultiple: false,
      ageValue: '',
      ageColumns: ['20-30歲', '30-40歲', '40-50歲', '50-60歲', '60歲以上'],
      showAge: false,
      typeValue: '',
      typeColumns: ['阿彌陀佛聖號', '觀音菩薩聖號', '阿彌陀經', '無量壽經', '其他佛號或經典'],
      showType: false,
      ageRadio :'',
      nikename : '',
      countStepper: 0,
      timesStepper: 0,
      message: '',
      age: 2,
      type: 1,
      dTitle: '我已随喜今日共修(打卡)',
      isCard: false,
      contentList: [],
    };
  },
  methods: {
    pause(){
        this.$refs.VueAliplayerV2.pause();
    },
    play(){
        this.$refs.VueAliplayerV2.play();
    },
    cut(playCut){
      this.$refs.VueAliplayerV2.loadByUrl(this.playCutUrl);
      this.playCut = playCut;
      this.playSeek = 0;
    },
    getStatus(){
        const status =  this.$refs.VueAliplayerV2.getStatus();
        if(status =='loading' || status =='ready' || status =='init'){
          this.play();
        }
        if((this.playSeek - this.$refs.VueAliplayerV2.getCurrentTime()) > 5  && !this.playCut && !this.playLive && status =='playing'){
          this.$refs.VueAliplayerV2.seek(this.playSeek);
          //this.$toast.success(this.playSeek);
          this.playLive = true;
          this.timer(5000);
        }
        //this.$toast.success(status + this.playSeek);
        console.log(`getStatus:`, status);
    },
    //加载课表
    onLoadKebiao() {
      this.axios.get(this.$tools.static_url  + '/jingzong_v2/json/table_vt.json'  )
      .then(response => {
        if(response.data.code==1){
          this.kblist = response.data.data;
          this.timer(500);
          this.updateKb();
          this.source = this.playUrl;
        }
      }) 
      this.finished = true;
    },
    onLoadConent(){
      this.axios.get(this.$tools.static_url  + '/jingzong_v2/json/2022_vt.json'  )
      .then(response => {
        if(response.data.code==1){
          this.contentList = response.data.data;
        }
      }) 
    },
    //更新课表
    updateKb(){
      this.playList = [];
      for (var i=0; i<this.kblist.length; i++){
        let item = this.kblist[i];
        let playList = [];
        if(this.$tools.timeCha(item.starttime_ux) > 0 && this.$tools.timeCha(item.endtime_ux) < 0) {
          playList.icon = "play-circle-o";
          this.playIndex = i; 
          playList.class= "is-active"
          this.playUrl = item.url;
          this.playSeek = this.$tools.timeCha(item.starttime_ux) / 1000;
        } else {
          if(this.$tools.timeCha(item.starttime_ux) > 0) {
            playList.icon = "passed";
          }else{
            playList.icon = "clock-o";
          }
        }
        playList.id = i;
        playList.time = item.starttime;
        playList.title = item.title;
        this.playList.push(playList);
      }  
    },
    //检查播放
    checkPlay(){
      this.getStatus();
      console.log('正在播放 ---playSeek:' + this.playSeek + '--- url:'+ this.playUrl + '---playLive:' + this.$refs.VueAliplayerV2.getCurrentTime());
      for (var i=0; i<this.kblist.length; i++){
        if (this.$tools.timeCha(this.kblist[i].starttime_ux) > 0 && this.$tools.timeCha(this.kblist[i].endtime_ux) < 0) {
          if(this.playIndex != i && this.playLive){
            this.updateKb();
            this.$refs.VueAliplayerV2.loadByUrl(this.playUrl);
            console.log('节目更新 ---' );
          }
        }  
      }  
    },
    timer(time) {    
      clearInterval(this.iTimer);
      this.iTimer = setInterval(()=>{
         this.checkPlay();
      },time)
    },
        /**
     * 播放器事件回调
     */
    handlePlay() {
        this.getStatus();
    },
    handleEnded() {
      this.cut(1);
    },
    handleCanplay(){
      //console.log('节handleCanplay ---' );
      //this.$toast.success("handleCanplay");
    },
    //返回
    onBack(){
      this.$router.push({ path: `/` })
    },
    ageConfirm(value, index) {
      this.showAge = false;
      this.ageValue = value ;
      this.age = index+1;
      console.log('age:' + this.age);
    },
    ageChange(picker, value, index) {
      console.log('age:' + picker +  value + index);
    },
    typeConfirm(value, index) {
      this.showType = false;
      this.typeValue = value ;
      this.type = index+1;
      console.log('type:' + this.type);
    },
    typeChange(picker, value, index) {
      console.log('age:' + picker +  value + index);
    },
  },

  mounted() {
    
    //this.$refs.VueAliplayerV2.setCover('https://www.jingzong.org/static/sandbox_v2/img/pureland/share-phone.png');
  },
  beforeDestroy() {
      clearInterval(this.iTimer);
      console.log('定时器清除 ---');
  },
  created() {
    this.onLoadKebiao();
    this.onLoadConent();
  },
  metaInfo () {
    return {
      title: '百日同步念佛共修'
    }
  },
};
</script>

<style  scoped>
.specail-live{
  margin:0 0 50px 0;
}
.van-list{
  margin:10px 0px;
  padding:0 10px;
}
.kb-item{
  height:40px;
  line-height: 40px;
  border-bottom: 1px solid #ebedf0;
}
.kb-ico{
  float: left;
  padding-left: 5px;
  font-size: 16px;
}

.kb-time{
  width:80px;
  float: left;
  text-align: center;
  font-size: 14px;
  color: #414141;
}
.kb-title{
  float: left;
  font-size: 14px;
  color: #414141;
}

.is-active{
  background-color:#f8ebbf;
}
.tb-10{
  padding:20px 10px 10px 10px;
}
.tb-content p{
  line-height: 25px;
  text-align: justify;
  padding:0 15px;
}
</style>