<template>
  <div class="video">
    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onBack">
      <template #right>
        <van-icon name="search" size="18" @click="onSearch"/>
      </template>
    </van-nav-bar>
    <van-tabs @click="getAlbum()" v-model="cid">
      <van-tab 
      v-for="(item,index) in clist" 
      :key="index"
      :title="item.title"
      :name="item.id"
      >
      <ul class="t-list margin-bottom-50">
          <li
          v-for="(item, index) in alist" 
          :key="index"
          class="t-item"
          >
            <a @click="goto(`${item.id}`)">
            <div class="t-item-title">{{ item.title }}</div>
            <div class="t-item-desc">
              <div class="van-multi-ellipsis--l2">
                {{ item.description }}
              </div>
              <div class="t-item-date">{{ item.date }} | {{ item.author }}</div>
            </div>
          </a>
        </li>
        </ul>
      </van-tab>
    </van-tabs>
  </div>

</template>
<script>

export default {
  data() {
    return {
      title: '文字',
      clist: [],
      alist: [],
      cid: 0,
      activeId: 0,
      page:0,
      last_page:1,
      metaTitle: '',
    }
  },
  methods: {
    //获取欄目列表
    getCategory(id){
      this.axios.get(this.$tools.api + '/book/getcategory')
      .then(response => {
        if(response.data.code==1){
          this.clist = response.data.rows;
          if(id==null){
            this.cid = this.clist[0].id;
          }else{
            this.cid = id;
          }
          this.getAlbum();
        }
      }) 
    },
    //获取專輯列表
    getAlbum(){
      if(this.cid != this.activeId){
        this.page = 0;
        this.last_page =1;
        this.$router.push({ path: `/book/${this.cid}` }) // -> /user/123
      }
      if(this.page < this.last_page){
        this.page ++;
        let url = this.$tools.api + '/book/getlist?cid='+this.cid+'&page=' + this.page;
        if(this.cid != this.activeId){
          this.alist = [];
          this.activeId = this.cid;
        }
        this.axios.get(url)
        .then(response => {
            let lis = response.data.rows.data;
            for (let i = 0; i < lis.length; i++) {
                this.alist.push(lis[i]); 
            }            
            if(this.page==1){
              this.last_page = response.data.rows.last_page;   
            } 
        }) 
      }
      this.metaTitle = this.$tools.getCategoryTitle(this.clist, this.cid);
    },
    onChange(index) {
      this.index = index;
    },   
    //返回
    onBack(){
      this.$router.push({ path: `/` }) // -> /user/123
    },
    goto(id){
      this.$router.push({ path: `/view/c/${id}` }) // -> /user/123
    },   
    // 滚动操作
    handleScroll() {
      var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
      var windowHeight =
          document.documentElement.clientHeight || document.body.clientHeight; //变量scrollHeight是滚动条的总高度
      var scrollHeight =
          document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight == scrollHeight) {
          this.getAlbum();
          return false;
      }
    },
    onSearch(){
      this.$router.push({ path: `/search/book` }) // -> /user/123
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll",this.handleScroll,true)
  },
  mounted(){
    // 监听滚动
    window.addEventListener('scroll',this.handleScroll,true)
  },
  created() {
    this.getCategory();
  },
  metaInfo () {
    return {
      title: this.metaTitle + '-' + this.title
    }
  },
};
</script>
<style  scoped>
.model{
  text-align: center;
  height:35px;
  line-height: 35px;
  background-color: #f7f8fa;
}
.is-model{
  color: #fff;
  background-color: #39a9ed;
}

</style>