<template>
  <div class="home">
    <van-nav-bar fixed>
      <template #left>
        <van-image
          height="40"
          lazy-load
          src="//new.jingzong.org/static/jingzong_v2/image/college-logo_40_v2.png"
        />
      </template>
      <template #right>
        <van-search
          v-model="value"
          shape="round"
          placeholder="请输入搜索关键词"
          @focus="onSearch"
        />
      </template>
    </van-nav-bar>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item         
        v-for="(item,index) in announce"
        :key="index"
        @click="goto(index)"
      >
      <img v-lazy="item.cover" class="swipe-img"/>
      </van-swipe-item>
    </van-swipe>
    <van-grid :column-num="4" class="nav-list">
      <van-grid-item  
      v-for="item in nav" 
      :key="item.id"
      :to="item.url">
      <span :class="item.ico"></span>
      <div class="nav-title">{{ item.title }}</div>
      </van-grid-item>
    </van-grid>
    <div class="update-video"><span>視頻更新</span></div>
    <ul class="list">
      <li 
      v-for="(item,index) in videoList" 
      :key="index"
      class="latest-info-item">
        <a @click="goVideo(index)">
          <img v-lazy="item.cover" alt="" class="item-img">
          <div class="item-desc">
            <div class="item-title">
              <p class="line-clamp-2" style="-webkit-box-orient: vertical;">{{ item.title }}</p>
            </div>
            <div class="item-bottom">
              <div class="item-model">{{ item.model_name }}</div>
              <div class="item-date">{{ item.date }}</div>
            </div>
          </div>
        </a>
      </li>
    </ul>

    <div class="update-video"><span>資訊更新</span></div>
    <ul class="t-list">
        <li
        v-for="(item, index) in textList" 
        :key="index"
        class="t-item"
        >
          <a @click="goText(index)">
          <div class="t-item-title">{{ item.title }}</div>
          <div class="t-item-desc">
            <div class="van-multi-ellipsis--l2">
              {{ item.description }}
            </div>
            <div class="t-item-date">{{ item.date }} | {{ item.author }}</div>
          </div>
        </a>
      </li>
    </ul>

    <div class="update-video"><span>電台更新</span></div>
    <ul class="list">
      <li 
      v-for="(item,index) in radioList" 
      :key="index"
      class="latest-info-item">
        <a @click="goRadio(index)">
          <img v-lazy="item.cover" alt="" class="item-img">
          <div class="item-desc">
            <div class="item-title">
              <p class="line-clamp-2" style="-webkit-box-orient: vertical;">{{ item.title }}</p>
            </div>
            <div class="item-bottom">
              <div class="item-model">電台</div>
              <div class="item-date">{{ item.date }}</div>
            </div>
          </div>
        </a>
      </li>
    </ul>
    <div class="footer">
      <!--<p><a href="https://beian.miit.gov.cn/">苏ICP备06004660号-2</a></p>-->
      <p><a href="http://www.amtb-aus.org/web">淨宗學院 電腦版</a></p>
    </div>
  </div>
</template>

<script>


export default {
  name: 'Home',
  data() {
    return {
      value: '',
      nav: [
        {id: 1, url:'alive',title :'直播', ico:'iconfont icon-jz-live'},
        {id: 2, url:'masterspeech',title :'講演', ico:'iconfont icon-jz-masterspeech '},
        {id: 3, url:'book',title :'文字', ico:'iconfont icon-jz-kecheng'},
        {id: 4, url:'picture',title :'圖片', ico:'iconfont icon-jz-picture'},
        {id: 5, url:'mobao',title :'墨寶', ico:'iconfont icon-jz-mobao'},
        {id: 6, url:'video',title :'影音', ico:'iconfont icon-jz-video'},
        {id: 7, url:'radio',title :'電台', ico:'iconfont icon-jz-radio'},
        {id: 8, url:'jiangyan',title :'全集', ico:'iconfont icon-jz-wuxin '}
      ],
      announce:[],
      videoList:[],
      textList:[],
      radioList:[],
    };
  },
  methods: {
    getSwipe(){
      this.axios.get(this.$tools.api + '/index/infolist?model=announce' )
      .then(response => {
        if(response.data.code==1){
          this.announce = response.data.rows;
        }
      }) 
    },
    video(){
      this.axios.get(this.$tools.api + '/index/infolist?model=video' )
      .then(response => {
        if(response.data.code==1){
          this.videoList = response.data.rows;
        }
      }) 
    },
    text(){
      this.axios.get(this.$tools.api + '/index/infolist?model=text' )
      .then(response => {
        if(response.data.code==1){
          this.textList = response.data.rows;
        }
      }) 
    },
    radio(){
      this.axios.get(this.$tools.api + '/radio/alist' )
      .then(response => {
        this.radioList = response.data;
      }) 
    },
    goto(index){
      let info = this.announce[index];
      console.log(info);
      if(info.tourl){
        if(info.tourl.indexOf("http") !== -1){
            let url = info.tourl;

            if(info.tourl.indexOf("sp") !== -1 || info.tourl.indexOf("fo") !== -1){
              url = "https://m.jingzong.net/sp/3";
            }
            if(info.tourl.indexOf("ap") !== -1){
              var _url = info.tourl.split('/');
              url = "https://m.jingzong.net/ap/" + _url[4];
            }
            window.location.href = url;
        }else{
          let url = this.$tools.fromatUrl(info.tourl);  
          let timestamp = Math.floor(Date.parse(new Date()) / 1000);
          if(url.model=='pureland' && url.controller=='show'){
            this.$router.push({ path: `/view/a/${url.id}` });
          }
          if(url.model=='pureland' && url.controller=='info'){
            this.$router.push({ path: `/announce/${url.id}` });
          }
          if(url.model=='live'){
            this.$router.push({ path: `/alive/${url.cid}/${timestamp}` });
          }
          if(url.model=='video'){
            let url = this.$tools.fromatVideoUrl(info.tourl);
            this.$router.push({ path: `/player/b/${url.cid}/${url.cnum}/${url.id}/${timestamp}` });
          }
        }
      }else{
        this.$router.push({ path: `/announce/${info.id}` });
      }

    },
    goVideo(index){
      let info = this.videoList[index];
      let model = 'b';
      let timestamp = Math.floor(Date.parse(new Date()) / 1000);
      if(info.pid == '9'){
        model = 'a';
      }
      this.$router.push({ path: `/player/${model}/${info.catid}/${info.cnum}/${info.id}/${timestamp}` })
    },
    goText(index){
      let info = this.textList[index];
      this.$router.push({ path: `/view/d/${info.id}` });
    },
    goRadio(index){
      let info = this.radioList[index];
      this.$router.push({ path: `/aplayer/${info.cate_id}/${info.album_id}/${info.id}` });
    },
    onSearch(){
      this.$router.push({ path: '/search' });
    },
    getImg320(url){
      return url + this.$tools.image_320;
    }
  },
  created() {
    this.getSwipe();
    this.video();
    this.text();
    this.radio();
  },
  metaInfo: {
      title: '澳洲淨宗學院 首頁',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' }
      ]
  }
}
</script>

<style>
  .home{
   background-color: #f7f8fa;  
  }
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    text-align: center;
  }
  .my-swipe{
    margin-top:46px;
  }
  .van-search {
    padding: 0px 12px;
    background-color: #fff;
  }
  .swipe-img{
    width:100%;
    height:200px;
    object-fit: cover;
  }
  .nav-list{
    background-color: #fff;
  }
  .nav-title{
    font-size: 15px;
    color: rgb(29, 29, 29);
    line-height: 40px;
  }
  .van-grid .iconfont{
    font-size: 28px!important;
    color: #fca24e;
  }
  .update-video{
    height:50px;
    line-height: 50px;
    padding-left:15px;
    background-color: #fff;
    margin:15px 0 2px 0;
  }
  .update-video span{
    font-size: 15px;
    padding:5px 10px;
    color:#fff;
    background-color:#fca24e;
  }
  .list{
    padding: 0 15px 20px 15px;
    background-color: #fff;
  }
  .list li{
    padding: 5px 0px;
    height: 100px;
    display: block;
    border-bottom: 1px solid #f5f5f5;
    background-color: #fff;
  }
  .list a {
    display: -webkit-box;
    text-decoration: none;
    color: #000;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
  .t-list{
    background-color: #fff;
  }
  .item-img{
    width: 150px;
    height: 80px;
    object-fit: cover;
    float: left;
    margin: 10px auto;
  }
  .item-desc {
    -webkit-flex: 1 1;
    flex: 1 1;
    height: 75px;
    padding-top: 8px;
    padding-left: 10px;
    float: left;
  }
  .item-title {
    font-size:14px;
    width: 100%;
    height: 45px;
    margin-bottom: 14px;
  }
  .line-clamp-2{
    margin: 0;
    padding: 0;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .item-model {
      width: 36px;
      height: 20px;
      font-size: 12px;
      text-align: center;
      padding: 0;
      color: #8fbd1d;
      border: 1px solid #8fbd1d;
      border-radius: 5px;
      float: left;
  }
  .item-date {
      width: 80px;
      font-size: 14px;
      text-align: right;
      color: #a2a2a2;
      float: right;
  }
  .footer{
    text-align: center;
    margin-top: 5px;
    padding-top:20px;
    margin-bottom: 80px;
    font-size:14px;
    background-color: #fff;
  }
</style>