<template>
  <div class="video">
    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onBack">
      <template #right>
        <van-icon name="search" size="18" @click="onSearch"/>
      </template>
    </van-nav-bar>
    <van-row class="model">
      <van-col span="12" v-bind:class="[ model=='mobao' ? 'is-model' : '']" @click="selectModel('mobao')">墨寶</van-col>
      <van-col span="12" v-bind:class="[ model=='jinshi' ? 'is-model' : '']" @click="selectModel('jinshi')">金石</van-col>
    </van-row>
    <van-tabs @click="getAlbum()" v-model="cid">
      <van-tab 
      v-for="(item,index) in clist" 
      :key="index"
      :title="item.title"
      :name="item.id"
      >
      <van-row class="v-list" gutter="15">
          <van-col span="12"
          v-for="(item, index) in alist" 
          :key="index"
          class="v-item"
          >
            <a @click="goto(index)">
            <img v-lazy="getUrl(item.cover)" alt="" class="v-item-img">
            <div class="v-item-desc">
              <div class="v-item-title van-multi-ellipsis--l1">
                {{ item.title }}
              </div>
            </div>
          </a>
        </van-col>
        </van-row>
      </van-tab>
    </van-tabs>
    <van-image-preview v-model="show" :images="images" @change="onChange" :startPosition="startPosition">
      <template v-slot:index>{{ titles[index] }}</template>
    </van-image-preview>
  </div>
</template>
<script>

export default {
  data() {
    return {
      title: '墨宝',
      model: 'mobao',
      clist: [],
      alist: [],
      cid: 593,
      activeId: 0,
      page:0,
      last_page:1,
      show: false,
      index: 0,
      images: [],
      titles: [],
      startPosition: 0,
      metaTitle: '',
    }
  },
  methods: {
    selectModel(name){
      this.model = name;
      if(name=='mobao'){
        this.getCategory(581);
      }else{
        this.getCategory(582);
      }
    },
    //获取欄目列表
    getCategory(id){
      this.axios.get(this.$tools.api + '/mobao/getcategory?cid='+id)
      .then(response => {
        if(response.data.code==1){
          this.clist = response.data.rows;
          this.getAlbum();
        }
      }) 
    },
    //获取專輯列表
    getAlbum(){
      if(this.cid != this.activeId){
        this.page = 0;
        this.last_page =1;
        this.$router.push({ path: `/mobao/${this.cid}` }) 
      }
      if(this.page < this.last_page){
        this.page ++;
        let url = this.$tools.api + '/mobao/album?aid='+this.cid+'&page=' + this.page;
        if(this.cid != this.activeId){
          this.alist = [];
          this.activeId = this.cid;
        }
        this.axios.get(url)
        .then(response => {
            let lis = response.data.rows.data;
            for (let i = 0; i < lis.length; i++) {
                this.alist.push(lis[i]); 
            }            
            if(this.page==1){
              this.last_page = response.data.rows.last_page;  
            } 
            this.images = this.$tools.fromatMobao(this.alist,"cover"); 
            this.titles = this.$tools.fromatMobao(this.alist,"title");    
        }) 
      }
      this.metaTitle = this.$tools.getCategoryTitle(this.clist, this.cid);
    },
    goto(index){
      this.startPosition = index;
      this.show = true;
    }, 
    onChange(index) {
      this.index = index;
    },     
    //返回
    onBack(){
      this.$router.push({ path: `/` }) // -> /user/123
    },
    getUrl(num){
      return this.$tools.getMobaoUrl(num, 'small', 'gif')
    },
    // 滚动操作
    handleScroll() {
        var scrollTop =
            document.documentElement.scrollTop || document.body.scrollTop; //变量windowHeight是可视区的高度
        var windowHeight =
            document.documentElement.clientHeight || document.body.clientHeight; //变量scrollHeight是滚动条的总高度
        var scrollHeight =
            document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + windowHeight == scrollHeight) {
            this.getAlbum();
            return false;
        }
    },
    onSearch(){
      this.$router.push({ path: `/search/mobao` }) // -> /user/123
    }
  },
  beforeDestroy() {
    window.removeEventListener("scroll",this.handleScroll,true)
  },
  mounted(){
    // 监听滚动
    window.addEventListener('scroll',this.handleScroll,true)
  },
  created() {
    this.getCategory(581);
  },
  metaInfo () {
    return {
      title: this.metaTitle + '-圖片'
    }
  },
};
</script>
<style  scoped>
.model{
  text-align: center;
  height:35px;
  line-height: 35px;
  background-color: #f7f8fa;
}
.is-model{
  color: #fff;
  background-color: #39a9ed;
}
</style>