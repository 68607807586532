<template>
  <div class="search">

    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onBack">
    </van-nav-bar>
    <div class="search-form">
      <van-search
      v-model="value"
      shape="round"
      background="#4fc08d"
      placeholder="请输入搜索关键词"
      class="search-input"
      />
      <van-button type="default" block class="search-btn" @click="onSearch">搜索</van-button>
    </div>
    <div class="search-content" v-show="isShow">
      <van-tabs @click="onChange" v-model="active">
        <van-tab v-for="(item,index) in list.list" :key="index" :title="item.model">
          <template #title> 
              <div >{{ item.title }}<span class="total">{{ item.total }}</span></div>
          </template>
          <ul class="t-list margin-bottom-50" v-if="model === 'text'">
            <li
            v-for="(item, index) in infolist" 
            :key="index"
            class="t-item"
            >
              <a @click="goText(`${item.id}`)">
                <div class="t-item-title">{{ item.title }}</div>
                <div class="t-item-desc">
                  <div class="van-multi-ellipsis--l2">
                    {{ item.description }}
                  </div>
                  <div class="t-item-date">{{ item.date }} | {{ item.author }}</div>
                </div>
              </a>
            </li>
          </ul>
          <van-row class="v-list" gutter="15" v-else-if="model === 'video'">
            <van-col span="12"
            v-for="(item, index) in infolist" 
            :key="index"
            class="v-item"
            >
              <a @click="goVideo(index)">
              <img v-lazy="item.cover" alt="" class="v-item-img">
              <div class="v-item-desc">
                <div class="v-item-title">
                  {{ item.title }}
                </div>
              </div>
            </a>
          </van-col>
          </van-row>
          <van-row class="v-list" gutter="15" v-else>
            <van-col span="12"
            v-for="(item, index) in infolist" 
            :key="index"
            class="v-item"
            >
            <a @click="goPic(index)">
              <img v-lazy="getUrl(item.cover)" alt="" class="v-item-img">
              <div class="v-item-desc">
                <div class="v-item-title van-multi-ellipsis--l2">
                  {{ item.title }}
                </div>
              </div>
            </a>
          </van-col>
        </van-row>
        </van-tab>
      </van-tabs>
    </div>
    <van-image-preview v-model="picShow" :images="images" @change="onChangePic" :startPosition="startPosition">
      <template v-slot:index>{{ titles[index] }}</template>
    </van-image-preview>
  </div>

</template>
<script>

export default {
  data() {
    return {
      title: '搜索',
      value:'',
      isShow: false,
      picShow:false,
      model: 'text',
      active: '',
      list:[],
      infolist:[],
      images: [],
      titles: [],
      startPosition: 0,
      index:0,
      metaTitle: '搜索页面',
    }
  },
  methods: {
    onSearch(){
      this.metaTitle = this.value;
      this.axios.get(this.$tools.api + '/index/getsearch?search=' + this.value + '&model=' + this.model )
      .then(response => {
        if(response.data.code==1){
          this.list = response.data;
          this.isShow = true;
          this.getSearch();
        }
      })  
    },
    getSearch(){
      this.axios.get(this.$tools.api + '/index/get_search_info?model=' + this.model + '&search=' + this.value)
      .then(response => {
        if(response.data.code==1){
          this.infolist = response.data.infolist;
          if(this.model == 'photo'){
            this.images = this.$tools.fromatPicture(this.infolist, "cover"); 
            this.titles = this.$tools.fromatPicture(this.infolist, "title"); 
          }
          if(this.model == 'mobao'){
            this.images = this.$tools.fromatMobao(this.infolist,"cover"); 
            this.titles = this.$tools.fromatMobao(this.infolist,"title");    
          }
        }
      })  
    },
    onChange(name, title) {
      this.model = title;
      this.getSearch();
    },   
    //返回
    onBack(){
      this.$router.push({ path: `/` }) // -> /user/123
    },
    goText(id){
      this.$router.push({ path: `/view/d/${id}` });
    },  
    goVideo(index){
      let info = this.infolist[index];
      let model = 'b';
      if(info.pid=='9'){
        model = 'a';
      }
      this.$router.push({ path: `/player/${model}/${info.catid}/${info.cnum}/${info.id}` })
    },
    goPic(index){
      this.startPosition = index;
      this.picShow = true;
    },
    onChangePic(index) {
      this.index = index;
    },   
    getUrl(cover){
      if(this.model=='mobao'){
        return this.$tools.getMobaoUrl(cover, 'small', 'gif');
      }else{
        return this.$tools.getPictureUrl(cover, this.$tools.image_320);
      }
      
    },
  },

  created() {

  },
  metaInfo () {
    return {
      title: this.metaTitle
    }
  },
};
</script>
<style  scoped>
.search-form{
  margin:10px;
}
.search-input{
  padding:10px 10px;
  margin-bottom: 5px;
}
.search-btn{

}
.total{
    display: inline-block;
    box-sizing: border-box;
    padding: 0 3px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    font-family: -apple-system-font, Helvetica Neue, Arial, sans-serif;
    line-height: 1.2;
    text-align: center;
    background-color: #ee0a24;
    border: 1px solid #fff;
    border-radius: 999px;
}
</style>