<template>
  <div class="specail-live">
    <van-nav-bar :title="playTitle" left-text="返回" left-arrow @click-left="onBack" right-text="刷新" @click-right="onClickRight">
    </van-nav-bar>
    <vue-aliplayer-v2 :source="source" ref="VueAliplayerV2" :options="options" @play="handlePlay" @ended="handleEnded" @canplay="handleCanplay"/>
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text=""
      @load="onLoadKebiao"
    >

      <div 
        class="van-clearfix kb-item"
        :class="item.class"
        v-for="item in kblist_text" 
        :key="item.id"
      >
        <div class="kb-time">{{ item.starttime }}</div>
        <div class="kb-title" >{{ item.title }}</div>
      </div>
    </van-list>
  </div>
</template>

<script>
//推荐第一种(仅v1.2.3)及以上的版本可用
import VueAliplayerV2 from "vue-aliplayer-v2";
export default {
  components: {
    VueAliplayerV2
  },
  data() {
    return {
      kblist:[],
      kblist_text:[
        {
          "id": "01",
          "type": "播出",
          "num": "PC-019-0002",
          "starttime": "00:00",
          "starttime_ux": "1646632210000",
          "endtime": "12:00",
          "endtime_ux": "1646639590000",
          "title": "北京時間 5月25-26日",
        },
        {
          "id": "02",
          "type": "播出",
          "num": "PC-019-0002",
          "starttime": "12:00",
          "starttime_ux": "1646632210000",
          "endtime": "14:10",
          "endtime_ux": "1646639590000",
          "title": "第一時繫念法事",
        },
        {
          "id": "03",
          "type": "播出",
          "num": "PC-019-0002",
          "starttime": "14:40",
          "starttime_ux": "1646632210000",
          "endtime": "15:40",
          "endtime_ux": "1646639590000",
          "title": "第二時繫念法事",
        },
        {
          "id": "04",
          "type": "播出",
          "num": "PC-019-0002",
          "starttime": "16:00",
          "starttime_ux": "1646632210000",
          "endtime": "18:00",
          "endtime_ux": "1646639590000",
          "title": "第三時繫念法事",
        },
      ],
      loading: false,
      finished: false,
      active:0,
      playTitle: '淨宗學院',
      playSeek: 0,
      playIndex: -1,
      playList: [],
      CurriculumId : 617,
      iTimer :null,
      fullHeight: document.documentElement.clientHeight - 100,
      options: {
        autoplay :false,
        isLive: true,   //切换为直播流的时候必填
        height: (document.documentElement.clientWidth *9) / 16 +'px',
        cover:'https://i.yyii.info//uploads/image/announce/20240523/8a0fdb5da6174ff2c88d463151e51d0e.jpg',
        skinLayout: [
          {name: "bigPlayButton", align: "cc"},
          {name: "H5Loading", align: "cc"},
          {
            name: "controlBar", align: "blabs", x: 0, y: 0,
            children: [
                {name: "playButton", align: "tl", x: 15, y: 12},
                {name:"fullScreenButton", align:"tr", x:10, y: 10},
                {name:"volume", align:"tr", x:5, y:10}
              ]
          }
        ]
      },
      source: 'https://media-tn.jingzong.org/hdlive/hd.m3u8',
      //source: 'https://live.ciguang.org/live/hd.m3u8?auth_key=1706948985-0-0-8ff64fa6987ddbf31c0a20865a04689f',
      //cover: 'www.jingzong.org/static/sandbox_v2/img/pureland/share-phone.png',
      show: true,
    };
  },
  methods: {
    pause(){
        this.$refs.VueAliplayerV2.pause();
    },
    play(){
        this.$refs.VueAliplayerV2.play();
    },
    handlePlay() {
        this.getStatus();
    },
    handleEnded() {
      
    },
    onClickRight(){
      location.reload()
    },
    getStatus() {
        const status = this.$refs.VueAliplayerV2.getStatus();
        console.log(`getStatus:`, status);
    },
    handleCanplay(){
      //console.log('节handleCanplay ---' );
      //this.$toast.success("handleCanplay");
    },
        //加载课表
    onLoadKebiao() {

      this.finished = true;
    },
    //返回
    onBack(){
      this.$router.push({ path: `/` })
    },
  },

  mounted() {
    let width = document.documentElement.clientWidth 
    if(width>600){
      width = 600
    }
    this.options.height=(width *9) / 16 +'px';
    //this.$refs.VueAliplayerV2.setCover('https://i.yyii.info//uploads/image/announce/20240407/5eadba5249045bc28dfe7d3d280be40f.jpg');
  },
  beforeDestroy() {
  },
  created() {

  },
  metaInfo () {
    return {
      title: '淨宗學院'
    }
  },
};
</script>

<style  scoped>
.specail-live{
  margin:0 0 50px 0;
}
.van-list{
  margin:10px 0px;
  padding:0 10px;
}
.kb-item{
  height:40px;
  line-height: 40px;
  border-bottom: 1px solid #ebedf0;
}
.kb-ico{
  float: left;
  padding-left: 5px;
  font-size: 16px;
}

.kb-time{
  width:80px;
  float: left;
  text-align: center;
  font-size: 14px;
  color: #414141;
}
.kb-title{
  float: left;
  font-size: 14px;
  color: #414141;
}

.is-active{
  background-color:#f8ebbf;
}
.tb-10{
  padding:20px 10px 10px 10px;
}
.content{
  padding: 10px;
  line-height: 25px;
  text-align: justify;
}
</style>