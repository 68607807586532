<template>
  <div class="view">
    <div class="back-img"  :style="{backgroundColor:color}">
      <van-image :src="img" />
    </div>
      <div class="c-content">
      <van-button  color="linear-gradient(to right, #ff6034, #ee0a24)" block url="/sp/live" size=”large“>立即参加</van-button>
    </div>
    <div class="c-content">
      <van-button color="#ff9d82" block url="https://www.jingzong.net/sj" size=”large“>舊版備用</van-button>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      title: '念佛共修',
      info: [],
      fullHeight: document.documentElement.clientHeight - 380,
      imgHeight: document.documentElement.clientHeight - 200,
      color: '#ffefd6',
      img : "//www.jingzong.org/static/sandbox_v2/img/pureland/bairi-01-v5.png",
      iswx: false,
    }
  },
  methods: {
    //获取欄目列表
    getInfo(id){
      this.axios.get(this.$tools.api + '/pureland/getpage/id/' + id)
      .then(response => {
          this.info = response.data;
      }) 
      this.title = this.json[id].title;
    },
 
    //返回
    onBack(){
      this.$router.push({ path: `/about` })  
    },
  },
  beforeDestroy() {

  },
  mounted(){

  },
  created() {
    if(this.$tools.IsWeixinOrAlipay()==13){
      this.iswx = true;
      this.img = "//www.jingzong.org/static/sandbox_v2/img/pureland/bairi-01-v4.png";
    }else{
      this.iswx = false;
      this.img = "//www.jingzong.org/static/sandbox_v2/img/pureland/bairi-01-v2.png";
    }
    this.getInfo(this.$route.params.id)
  },
  metaInfo () {
    return {
      title: this.title
    }
  },
};
</script>
<style  scoped>
.view{
    background-color: #f7f8fa;
    margin-bottom: 70px;
}
.c-content{
  padding:5px 5px;
}
.t-10{
  padding:10px 0 0 0;
}
</style>