import { render, staticRenderFns } from "./Pureland.vue?vue&type=template&id=1097f7dc&scoped=true&"
import script from "./Pureland.vue?vue&type=script&lang=js&"
export * from "./Pureland.vue?vue&type=script&lang=js&"
import style0 from "./Pureland.vue?vue&type=style&index=0&id=1097f7dc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1097f7dc",
  null
  
)

export default component.exports