<template>
  <div class="videoplayer">
    <van-nav-bar :title="playTitle" left-text="返回" left-arrow @click-left="onBack">
    </van-nav-bar>
    <div id="mse" v-show="isVideo"></div>
    <div id="vs" v-show="!isVideo"></div>
    <div class= "playing-title" v-show="!isVideo">
      正在播放:{{playTitle}}
    </div>
    <van-row class="v-desc">
      <van-col span="14">
        <div class="v-albuum-title">{{ album.title }}<span class="v-total">共{{ album.total }}集</span></div>
      </van-col>
      <van-col span="10" >
        <ul class="v-tools">
          <li><van-icon name="like-o" /></li>
          <li @click="download()"><van-icon name="down" /></li>
          <li @click="showShare = true"><van-icon name="share-o" /></li>
        </ul>
      </van-col>
    </van-row>
    <van-tabs v-model="activeName" @click="onClick">
      <van-tab title="視頻列表" name="a" >
        <div v-show="noVideo" class="novideo">無視頻</div>
        <van-grid :column-num="6" square class="xj-list" v-show="!noVideo">
          <van-grid-item 
          v-for="(item,index) in list" 
          :key="index" 
          v-bind:class="[ (info.id==item.id) && isVideo ? 'is-active' : '']"
          @click="changePlay(index)" 
          >
            <div  
            v-if="item.ifile.indexOf('8') !== -1"        
            >{{ item.episode ? item.episode : index+1 }}</div>
          </van-grid-item>
        </van-grid> 
        
      </van-tab>
      <van-tab title="音頻列表" name="b">
        <van-grid :column-num="6" square class="xj-list">
          <van-grid-item 
          v-for="(item,index) in list" 
          :key="index" 
          v-bind:class="[ (info.id==item.id) && !isVideo ? 'is-active' : '']"
          @click="changeAudio(index)" 
          >
            <div  
            v-if="item.ifile.indexOf('1') !== -1"        
            >{{ item.episode ? item.episode : index+1 }}</div>
          </van-grid-item>
        </van-grid>        
      </van-tab>
      <van-tab title="字幕文稿" name="c" > 
        <div class="content" >
          <van-loading size="24px" v-show="isShow" class="v-loading">加载中...</van-loading>
          <div v-show="!isShow" v-html="playContent"></div>
        </div>
      </van-tab>
    </van-tabs>
    <van-share-sheet
      v-model="showShare"
      title="立即分享给好友"
      :options="options"
      @select="onSelect"
    />
  </div>
</template>

<script>
import 'xgplayer';
import HlsJsPlayer from 'xgplayer-hls.js';
import Music from 'xgplayer-music';
export default {
  data() {
    return {
      active: 0,
      activeName: 'a',
      iPlayer: null,
      model: 'a',
      playUrl: '',
      url: '',
      isShow: true,
      playSeek: 0,
      list: [],
      playIndex: 0,
      playTitle: '加载中',
      playModel: 'video',
      info:[],
      playContent: '暫無',
      album:{},
      mp3Player: null,
      mp3Info:[],
      isVideo: true,
      showShare: false,
      metaTitle:'播放页面',
      noVideo: false,
      options: [
        [
          { name: '复制链接', icon: 'link' },
          { name: '二维码', icon: 'qrcode' },
        ],
      ],
    };
  },
  methods: {
    initPlayer() {
      this.iPlayer = new HlsJsPlayer({
        id: 'mse',
        url: this.playUrl,
        fluid: true,
        autoplay: true,
        playsinline: true,
      });
      this.iPlayer.on('complete',()=>{
        console.log('complete')
      })
      this.iPlayer.on('ended',()=>{
        console.log('ended')
        this.playNext();
      })
      this.isVideo = true;
    },
    initMp3(){
      this.mp3Player = new Music({
        id: 'vs',
        url: this.mp3Info,
        volume: 1,
        height: 80,
        preloadNext: false,
        switchKeepProgress: false,
        ignores:['cover','backward','forward']
      })
      this.isVideo = false;
      this.mp3Player.setIndex(this.playIndex);
    },
    //获取專輯列表
    initVideo(){
      this.info = this.list[this.playIndex];
      this.playUrl = this.$tools.getVideoNumUrl(this.$tools.media + '360p/', this.info.num,'m3u8');
      this.playTitle = this.info.title;
      if(this.iPlayer==null){
        this.initPlayer();
      }else{
        this.iPlayer.src = this.playUrl;
      }
      this.isVideo = true;
      if(this.mp3Player){
        this.mp3Player.pause();
      }
      this.iClick();
      let timestamp = Math.floor(Date.parse(new Date()) / 1000);
      this.$router.push({ path: `/player/${this.url}${this.list[this.playIndex].id}/${timestamp}` });
    },
    changePlay(index){
      this.playIndex = index;
      this.initVideo();
    },
    changeAudio(index){
      this.isVideo = false;
      this.info = this.list[index];
      this.playIndex = index;
      this.playTitle = this.info.title;
      this.mp3Info = this.$tools.fromatAudio(this.list)
      if(this.iPlayer){
        this.iPlayer.pause();
      }
      if(this.mp3Player==null){
        this.initMp3();
      }else{
        this.mp3Player.setIndex(this.playIndex);
      }
    },
    async getText(){
      this.playContent = await this.$tools.getTxt(this.info.num);
      this.isShow = false;
    },
    onClick(name){
      //console.log(name)
      if(name=='c'){
        this.getText();
      }
    },
    playNext(){ 
      if(this.playIndex < this.list.length-1){
        this.playIndex++;
      }else{
        this.playIndex = 0;
      }
      this.initVideo();
    },
    //返回
    onBack(){
      if(this.model == 'a'){
        this.$router.push({ path: `/masterspeech` })
      }
      if(this.model == 'b'){
        this.$router.push({ path: `/video` })
      }  
      if(this.model == 'c'){
        this.$router.push({ path: `/jiangyan` })
      }         
    },
    //文件下载
    download(){  
      let url = ''
      if(this.isVideo){
         url = this.$tools.getVideoNumUrl(this.$tools.download_url + 'mp4/', this.info.num,'mp4');
      }else{
         url = this.$tools.getVideoNumUrl(this.$tools.download_url + 'mp3/', this.info.num,'mp3');
      }
      window.open(url, "_blank");
    },
    onSelect(option) {
      console.log(option);
      if(option.icon=='link'){
        this.copyShaneUrl();
      }
      this.showShare = false;
    },
    copyShaneUrl(){
      var url = window.location.href;
      this.$copyText(url).then(function (e) {
        console.log(e)
      }, function (e) {
        console.log(e)
      })
    },
    iClick(){
      let url = this.$tools.api + '/video/click?num=' + this.info.num;
      this.axios.get(url)
      .then(response => {
        this.info.click = response.data.click;
        //console.log(this.info.click);
      }) 
    }
  },
  mounted() {
    
  },
  beforeDestroy() {
      if(this.iPlayer){
        this.iPlayer.pause();
        this.iPlayer.off('ready',function(){});
        this.iPlayer.off('complete',function(){});
        this.iPlayer.off('ended',function(){});
        this.iPlayer.destroy(true)
        this.iPlayer = null;
      }
      location.reload();
  },
  created() {
    this.model = this.$route.params.model;
    let url = this.$tools.api + '/video/get_video_list?cid=' + this.$route.params.cid + '&num=' + this.$route.params.num + '&id=' + this.$route.params.id;
    this.url = this.$route.params.model + "/" + this.$route.params.cid +  "/" + this.$route.params.num + "/",
    this.axios.get(url)
    .then(response => {
      if(response.data.code==1){
        this.playIndex = response.data.index;
        this.list = response.data.rows;
        this.album = response.data.album;
        if(this.album.mp4){
          this.initVideo();
        }else{
          this.activeName = 'b';
          this.noVideo = true;
          //this.initMp3();
          this.changeAudio(this.playIndex);
        }
      }
    })  
  },
  computed: {
    // 滚动区高度
    // (业务需求：手机屏幕高度减去头部标题和底部tabbar的高度，当然这2个高度也是可以动态获取的)
    scrollerHeight: function() {
      return (window.innerHeight - 46 -235 -50) + 'px';
    }
  },
  metaInfo () {
    return {
      title: this.playTitle
    }
  },
};
</script>

<style  scoped>
.videoplayer{
  background-color: #F2F3F5;
}
.xj-list{
  overflow: auto;
  padding:10px 10px;
  background-color: #FFF;
  margin-bottom: 60px;
}

.is-active{
  background-color:#f8ebbf;
}

.content{
  font: 20px;
  line-height: 35px;
  padding:20px 30px 30px 30px;
  background-color: #FFF;
  margin-bottom: 60px;
}
.v-loading{
  padding-top:20px;
  text-align: center;
}
.v-desc{
  display: block;
  clear: both;
  padding:5px 15px;
  margin-bottom: 5px;
  background-color: #FFF;
}
.v-tools{
  text-align:right;
  height:50px;
  line-height: 50px;
}
.v-tools li{
  display:inline;
  padding:10px 10px;
  background-color:#f7f7f7;
  margin:0 3px;
}
.v-total{
  font-size: 12px;
  padding-left:5px;
  color:#8b8b8b;
}

.xgplayer-next{
  display: none!important;
}
.novideo{
  padding:20px 0px;
  text-align: center;
}
.playing-title{
  font-size: 20px;
  padding:20px 10px;
}
/* .xgplayer-name{
  需要修改xgplayer源文件
  font-size: 20px!important;
} */
</style>