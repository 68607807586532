<template>
  <div class="about">

    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onBack">
    </van-nav-bar>
    <van-image
      class="college-logo"
      lazy-load
      :src="logo"
    />
    <van-cell title="捐款賬號" icon="balance-pay" is-link to="/aview/1">
      <!-- 使用 right-icon 插槽来自定义右侧图标 -->
      <template #right-icon>
        <van-icon name="arrow" class="arrow" />
      </template>
    </van-cell>
    <van-cell title="聯繫我們" icon="contact" is-link to="/aview/2">
      <!-- 使用 right-icon 插槽来自定义右侧图标 -->
      <template #right-icon>
        <van-icon name="arrow" class="arrow" />
      </template>
    </van-cell>
    <!-- <van-cell title="留言反饋" icon="smile-o" is-link to="/feedback">
      <template #right-icon>
        <van-icon name="arrow" class="arrow" />
      </template>
    </van-cell> -->
  </div>

</template>
<script>

export default {
  data() {
    return {
      title: '關於學院',
      clist: [],
      cid: 0,
      activeId: 0,
      logo: '//new.jingzong.org/static/jingzong_v2/image/college-logo_700.png'
    }
  },
  methods: {
    //获取欄目列表
    getCategory(id){
      this.axios.get(this.$tools.api + '/book/getcategory')
      .then(response => {
        if(response.data.code==1){
          this.clist = response.data.rows;
          if(id==null){
            this.cid = this.clist[0].id;
          }else{
            this.cid = id;
          }
        }
      }) 
    },
    onChange(index) {
      this.index = index;
    },   
    //返回
    onBack(){
      this.$router.push({ path: `/` }) // -> /user/123
    },
    goto(id){
      this.$router.push({ path: `/aview/${id}` }) // -> /user/123
    },   
  },

  created() {
    this.getCategory();
  },
  metaInfo () {
    return {
      title: this.title
    }
  },
};
</script>
<style  scoped>
.college-logo{
  text-align: center;
  padding:70px 100px;
}
.college-logo img{
    width:100%;
}
</style>