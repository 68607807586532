<template>
  <div class="view">
    <van-popup v-model="isShow" closeable
      class="overlay-bg"
      position="bottom"
      close-icon="clear"
      :style="{ height: '100%' }">
      <van-image
          :src="downBg"
          :height="imgHeight"
          fit="contain"
          @click = "onBack"
        />
      </van-popup>
    <van-nav-bar :title="title" left-text="首页" left-arrow @click-left="onBack" v-show="!isWx">
    </van-nav-bar>
    <vue-aliplayer-v2 :source="source" ref="VueAliplayerV2" :options="options" @play="handlePlay"/>
    <van-row>
      <van-col span="24" class="video-title">
        <div>{{info.title}}</div>
      </van-col>
    </van-row>
    <div class="padding-10">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-row
          v-for="(item,index) in list" 
          :key="index"  >
          <van-col span="24" :class="index==select?'card-list-select':'card-list'"  @click="changePlay(index)">
            <van-image
              lazy-load
              :src="item.cover"
            />
            <div class="card-list-title">{{item.title}}</div>
          </van-col>
        </van-row>
      </van-list>
    </div>
    <van-divider
      :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '10px 16px' }"
    >
     訪問量： {{ count }} 
    </van-divider>
    <div class="c-content" v-show="isWx">
      <van-button  color="linear-gradient(to right, #ff6034, #ee0a24)" block url="https://cz-qiniu.ciguang.tv/uploads/image/appinfo/20220316/58abfdc1701f3c99673f2bb6b3629429.jpg" size=”large“>分享二維碼（點擊後，長按圖片轉發）</van-button>
    </div>
    <div class="c-content">
     <van-button  color="linear-gradient(to right, #ff6034, #ee0a24)" block url="/sp/live" size=”large“>立即参加百日念佛共修</van-button>
    </div>
    <div class="c-content">
     <van-button icon="share" color="#0080ff" block url="https://m.jingzong.org/i" size=”large“>淨宗學院</van-button>
    </div>
  </div>
</template>
<script>

import VueAliplayerV2 from "vue-aliplayer-v2";
export default {
  components: {
    VueAliplayerV2
  },
  data() {
    return {
      title: '知恩報恩-恩師九六華誕',
      info: [],
      isShow: true,
      isWx: false,
      count: 1000,
      finished: true,
      loading: true,
      rowClass : 'card-list',
      select: 0,
      downBg: "https://cz-qiniu.ciguang.tv/uploads/image/announce/20220317/167909b34b249f67f0c9fd6b6e4fb3d4.png?imageMogr2/thumbnail/1080x1920%3E/format/jpg/quality/100|imageslim",
      imgHeight: document.documentElement.clientHeight,
      options: {
        autoplay :true,
        isLive: false,   //切换为直播流的时候必填
        controlBarVisibility	: 'click',
        skinLayout: [
          {name: "bigPlayButton", align: "cc"},
          {name: "H5Loading", align: "cc"},
          {
            name: "controlBar", align: "blabs", x: 0, y: 0,
            children: [
                {name: "playButton", align: "tl", x: 15, y: 12},
                {name: "progress", align: "blabs", x: 0, y: 44},
                {name: "timeDisplay", align: "tl", x: 10, y: 7},
                {name:"fullScreenButton", align:"tr", x:10, y: 10},
                {name:"volume", align:"tr", x:5, y:10}
              ]
          }
        ]
      },
      source: '',
      list : [],
    }
  },
  methods: {
    //加载列表
    onLoadList() {
      this.axios.get('https://www.jingzong.net/sp/shi?m=json')
      .then(response => {
        if(response.data.code==1){
          this.list = response.data.data;
          this.changePlay(0);
          this.countClick();
        }
      }) 
    },
    countClick(){
      this.axios.get('https://www.jingzong.net/web/special/total?t=2')
      .then(response => {
        if(response.data.code==1){
          this.count = response.data.count;
        }
      }) 
    },
    handleDown(url){
      window.open(url);
    },
    handlePlay(){},
    //返回
    onBack(){
      this.$router.push({ path: `/i` })  
    },
    onLoad(){
      console.log('---');
    },
    changePlay(index){
      this.info = this.list[index];  
      this.select = index;
      this.source = this.$tools.getVideoNumUrl(this.$tools.m3u8_url + '360p/', this.info.num,'m3u8');
      document.documentElement.scrollTop = 0;
    },
  },
  beforeDestroy() {

  },
  mounted(){
    setInterval(()=>{
         this.isShow = false;
    },5000)
  },
  created() {
    this.onLoadList();
    if(this.$tools.IsWeixinOrAlipay()==13){
      this.isWx = true;
    }
  },
  metaInfo () {
    return {
      title: this.title
    }
  },
};
</script>
<style  scoped>
.view{
    background-color: #f7f8fa;
    margin-bottom: 70px;  
}
.c-content{
  padding:5px 5px;
}
.video-title{
  background-color: #fff;
  text-align:center;
  font-size:18px;
  padding:15px;
  margin-bottom:15px;
}
.tb-10{
  padding:10px 0;
}
.padding-10{
  padding:10px 10px;
}
.card-list{
    padding:10px 10px;
    background-color: #fff;
    margin-bottom: 10px;
}
.card-list-select{
    padding:10px 10px;
    background-color: rgb(238, 204, 110);
    margin-bottom: 10px;
}
.card-list-title{
  padding:5px 0;
}
.overlay-bg{
  background-color: #fcf7eb;
}
.zhien{
  padding:10px 10px;
  background-color: #fff;
  margin:0px 10px;
}
</style>