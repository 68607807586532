<template>
  <div class="specail-live">
        <van-nav-bar :title="playTitle" left-text="返回" left-arrow @click-left="onBack">

    </van-nav-bar>
    <vue-aliplayer-v2 :source="source" ref="VueAliplayerV2" :options="options" @play="handlePlay" @ended="handleEnded" @canplay="handleCanplay"/>
    <van-tabs v-model="active">

      <van-tab title="時間表">
        <div class="tb-10">
          <van-button icon="share-o" type="warning" block  @click = "changeLive()">{{backTitle}}</van-button>
        </div>
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text=""
          @load="onLoadKebiao"
        >
          <div 
            class="van-clearfix kb-item"
            :class="item.class"
            v-for="item in playList" 
            :key="item.id"
          >
            <div class="kb-ico"><van-icon :name="item.icon" /></div>
            <div class="kb-time">{{ item.time }}</div>
            <div class="kb-title" >{{ item.title }}</div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="紀念冊">
        <div class="tb-10" v-show="!isCard">
          <div class="content"></div>
          <div class="tb-10">
                    <van-image
          @click="toBook"
          src="https://cz-qiniu.ciguang.tv/uploads/image/apparticle/20220901/087903ceffecdbc052f166f0f7d7410a.jpg"
        />
          </div>
        </div>
      </van-tab>
      <van-tab title="淨公道影">
        <div class="tb-10">
          <van-button icon="share-o" type="warning" block  @click = "toApp()">淨公道影APP下載</van-button>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
//推荐第一种(仅v1.2.3)及以上的版本可用
import VueAliplayerV2 from "vue-aliplayer-v2";
export default {
  components: {
    VueAliplayerV2
  },
  data() {
    return {
      kblist:[],
      kblist_text:[{
                "id": "04",
                "type": "播出",
                "num": "PC-019-0002",
                "starttime": "08:00",
                "starttime_ux": "1646632210000",
                "endtime": "13:10",
                "endtime_ux": "1646639590000",
                "title": "念佛 第一支香",
                "url": this.$tools.media +"360p/PC/PC-019/PC-019-0001/PC-019-0001.m3u8"
            },
      ],
      loading: false,
      finished: false,
      active:0,
      playTitle: '淨空老和尚圓寂傳供讚頌大典',
      playSeek: 0,
      playIndex: -1,
      playList: [],
      CurriculumId : 617,
      iTimer :null,
      fullHeight: document.documentElement.clientHeight - 100,
      options: {
        autoplay :true,
        isLive: true,   //切换为直播流的时候必填
        height: (document.documentElement.clientWidth *9) / 16 +'px',
        skinLayout: [
          {name: "bigPlayButton", align: "cc"},
          {name: "H5Loading", align: "cc"},
          {
            name: "controlBar", align: "blabs", x: 0, y: 0,
            children: [
                {name: "playButton", align: "tl", x: 15, y: 12},
                {name:"fullScreenButton", align:"tr", x:10, y: 10},
                {name:"volume", align:"tr", x:5, y:10}
              ]
          }
        ]
      },
      source: 'https://i.ciguang.tv/hd/fahui.m3u8',
      //cover: 'www.jingzong.org/static/sandbox_v2/img/pureland/share-phone.png',
      show: true,
      isShowMultiple: false,
      ageValue: '',
      ageColumns: ['20-30歲', '30-40歲', '40-50歲', '50-60歲', '60歲以上'],
      showAge: false,
      typeValue: '',
      typeColumns: ['阿彌陀佛聖號', '觀音菩薩聖號', '阿彌陀經', '無量壽經', '其他佛號或經典'],
      showType: false,
      ageRadio :'',
      nikename : '',
      countStepper: 0,
      timesStepper: 0,
      message: '',
      age: 2,
      type: 1,
      dTitle: '我已随喜今日共修(打卡)',
      isCard: false,
      count : 0,
      bookUrl: 'https://www.jingzong.net/web/special/book',
      backUrl:false,
      backTitle:"切換到備用直播地址",
    };
  },
  methods: {
    pause(){
        this.$refs.VueAliplayerV2.pause();
    },
    play(){
        this.$refs.VueAliplayerV2.play();
    },
    changeLive(){
      var url = 'https://i.ciguang.tv/hd/fahui.m3u8';
      this.backTitle = "切換到備用直播地址";
      if(!this.backUrl){
        this.backTitle = "切換回主直播地址";
        url = 'https://live.ciguang.tv/live/fahui.m3u8?auth_key=5262109501-0-0-67fa8d02ca47d6d5f78b08abadcb729b';
      }
      console.log(this.source);
      this.backUrl = !this.backUrl;
      this.source = url;
    },
    //共修人數
    onLoadCount() {
      this.axios.get(this.$tools.api + '/special/count?type=3'  )
      .then(response => {
        if(response.data.code==1){
          this.count = response.data.count;
        }
      }) 
      this.finished = true;
    },
    getStatus(){
        const status =  this.$refs.VueAliplayerV2.getStatus();
        if(status =='loading' || status =='ready' || status =='init'){
          this.play();
        }
        if((this.playSeek - this.$refs.VueAliplayerV2.getCurrentTime()) > 5  && status =='playing'){
          this.timer(5000);
        }
        console.log(`getStatus:`, status);
    },
    //加载课表
    onLoadKebiao() {
      this.axios.get(this.$tools.api + '/special/getkebiao?date=0&type=2&id=' + this.CurriculumId  )
      .then(response => {
        if(response.data.code==1){
          this.kblist = response.data.data;
          this.timer(500);
          this.updateKb();
          this.checkDaka();
        }
      }) 
      this.finished = true;
    },
    //更新课表
    updateKb(){
      this.playList = [];
      for (var i=0; i<this.kblist.length; i++){
        let item = this.kblist[i];
        let playList = [];
        if(this.$tools.timeCha(item.starttime_ux) > 0 && this.$tools.timeCha(item.endtime_ux) < 0) {
          playList.icon = "play-circle-o";
          this.playIndex = i; 
          playList.class= "is-active"
          this.playSeek = this.$tools.timeCha(item.starttime_ux) / 1000;
        } else {
          if(this.$tools.timeCha(item.starttime_ux) > 0) {
            playList.icon = "passed";
          }else{
            playList.icon = "clock-o";
          }
        }
        playList.id = i;
        playList.time = item.starttime;
        playList.title = item.title;
        this.playList.push(playList);
      }  
    },
    //检查播放
    checkPlay(){
      this.getStatus();
      console.log('正在播放 ---playSeek:' + this.playSeek + '---playLive:' + this.$refs.VueAliplayerV2.getCurrentTime());
      for (var i=0; i<this.kblist.length; i++){
        if (this.$tools.timeCha(this.kblist[i].starttime_ux) > 0 && this.$tools.timeCha(this.kblist[i].endtime_ux) < 0) {
          if(this.playIndex != i && this.playLive){
            this.updateKb();
            console.log('节目更新 ---' );
          }
        }  
      }  
    },
    timer(time) {    
      clearInterval(this.iTimer);
      this.iTimer = setInterval(()=>{
         this.checkPlay();
      },time)
    },
        /**
     * 播放器事件回调
     */
    handlePlay() {
        this.getStatus();
    },
    handleEnded() {
      
    },
    handleCanplay(){
      //console.log('节handleCanplay ---' );
      //this.$toast.success("handleCanplay");
    },
    //返回
    onBack(){
      this.$router.push({ path: `/` })
    },
    toApp(){
      window.location.href = 'https://m.jingzong.net/dy';
    },
    toBook(){
      window.location.href = this.bookUrl;
    },
    ageConfirm(value, index) {
      this.showAge = false;
      this.ageValue = value ;
      this.age = index+1;
      console.log('age:' + this.age);
    },
    ageChange(picker, value, index) {
      console.log('age:' + picker +  value + index);
    },
    typeConfirm(value, index) {
      this.showType = false;
      this.typeValue = value ;
      this.type = index+1;
      console.log('type:' + this.type);
    },
    typeChange(picker, value, index) {
      console.log('age:' + picker +  value + index);
    },
    async onSubmit() {
      let param = 'model=jl&' + 'name=' + this.nikename
        +'&age=' + this.age
        +'&gender=' + this.ageRadio
        +'&type=' + this.type
        +'&count=' + this.countStepper
        +'&times=' + this.timesStepper
        +'&message=' + this.message;
      let info = await this.$tools.postData('/special/postdata?' + param);
      if(info.code===1){
        this.$toast.success('回向成功');
      }
    },
    async clickDaka() {
      let info = await this.$tools.postData('/special/postdata?model=daka&name='+this.nikename);
      if(info.code===1){
        this.$toast.success('打卡成功');
        this.isCard = true;
      }
    },
    async checkDaka() {
      let info = await this.$tools.getData('/special/today');
      if(info.code===1){
        this.nikename = info.daka.name;
        this.isCard = (info.daka.today)?true:false;
      }
      console.log('server:' + info.daka.name);
    },
  },

  mounted() {
    
    //this.$refs.VueAliplayerV2.setCover('https://www.jingzong.org/static/sandbox_v2/img/pureland/share-phone.png');
  },
  beforeDestroy() {
      clearInterval(this.iTimer);
      console.log('定时器清除 ---');
  },
  created() {
    this.onLoadKebiao();
    this.onLoadCount();
  },
  metaInfo () {
    return {
      title: '大典直播'
    }
  },
};
</script>

<style  scoped>
.specail-live{
  margin:0 0 50px 0;
}
.van-list{
  margin:10px 0px;
  padding:0 10px;
}
.kb-item{
  height:40px;
  line-height: 40px;
  border-bottom: 1px solid #ebedf0;
}
.kb-ico{
  float: left;
  padding-left: 5px;
  font-size: 16px;
}

.kb-time{
  width:80px;
  float: left;
  text-align: center;
  font-size: 14px;
  color: #414141;
}
.kb-title{
  float: left;
  font-size: 14px;
  color: #414141;
}

.is-active{
  background-color:#f8ebbf;
}
.tb-10{
  padding:20px 10px 10px 10px;
}
.content{
  padding: 10px;
  line-height: 25px;
  text-align: justify;
}
</style>