import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Live from '../views/Live.vue'
import MasterSpeech from '../views/MasterSpeech.vue'
import VideoPlayer from '../views/VideoPlayer.vue'
import Video from '../views/Video.vue'
import JiangYan from '../views/JiangYan.vue'
import Mobao from '../views/Mobao.vue'
import Picture from '../views/Picture.vue'
import Radio from '../views/Radio.vue'
import AudioPlayer from '../views/AudioPlayer.vue'
import Book from '../views/Book.vue'
import View from '../views/View.vue'
import Pureland from '../views/Pureland.vue'
import Master from '../views/Master.vue'
import About from '../views/About.vue'
import AboutView from '../views/AboutView.vue'
import Announce from '../views/Announce.vue'
import Search from '../views/Search.vue'
import Feedback from '../views/Feedback.vue'
import NotFound from '../views/404.vue'
import Special from '../views/Special.vue'
import SLive from '../views/Special/Live.vue'
import SLiveVt from '../views/Special/LiveVt.vue'
import SDownload from '../views/Special/Download.vue'
import SVideo from '../views/Special/Video.vue'
import Birthday from '../views/Special/Birthday.vue'
import Daoying from '../views/Special/Daoying.vue'
import Ciguang from '../views/Special/Ciguang.vue'
import Qunshu from '../views/Special/Qunshu.vue'
import PurelandLive from '../views/Special/Pureland.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/i',
    name: 'Home',
    component: Home
  },
  {
    path: '/videoPlayer',
    name: 'videoplayer',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/VideoPlayer.vue')
  },
  {
    path: '/live',
    name: 'Live',
    component: Live,
    children: [
      {
        path: ":id",
        component: Live,
      },
    ],
  },
  //规避微信屏蔽
  {
    path: '/alive',
    name: 'Live',
    component: Live,
    children: [
      {
        path: ":id",
        component: Live,
      },
      {
        path: ":id/:time",
        component: Live,
      },
    ],
  },
  {
    path: '/web/live/index/cid',
    name: 'Live',
    component: Live,
    children: [
      {
        path: ":id",
        component: Live,
      },
      {
        path: ":id/:time",
        component: Live,
      },
    ],
  },
  {
    path: '/masterspeech',
    name: 'MasterSpeech',
    component: MasterSpeech,
    children: [
      {
        path: ":id",
        component: MasterSpeech,
      },
    ],
  },
  {
    path: '/video',
    name: 'Video',
    component: Video,
    children: [
      {
        path: ":id",
        component: Video,
      },
    ],
  },
  {
    path: '/jiangyan',
    name: 'JiangYan',
    component: JiangYan,
    children: [
      {
        path: ":id",
        component: JiangYan,
      },
    ],
  },
  {
    path: '/player',
    name: 'VideoPlayer',
    component: VideoPlayer,
    children: [
      {
        path: ":model/:cid/:num",
        component: VideoPlayer,
      },
      {
        path: ":model/:cid/:num/:time",
        component: VideoPlayer,
      },
      {
        path: ":model/:cid/:num/:id",
        component: VideoPlayer,
      },
      {
        path: ":model/:cid/:num/:id/:time",
        component: VideoPlayer,
      },
    ],
  },
  {
    path: '/mobao',
    name: 'Mobao',
    component: Mobao,
    children: [
      {
        path: ":id",
        component: Mobao,
      },
    ],
  },
  {
    path: '/picture',
    name: 'Picture',
    component: Picture,
    children: [
      {
        path: ":id",
        component: Picture,
      },
    ],
  },
  {
    path: '/radio',
    name: 'Radio',
    component: Radio,
    children: [
      {
        path: ":id",
        component: Radio,
      },
    ],
  },
  {
    path: '/aplayer',
    name: 'AudioPlayer',
    component: AudioPlayer,
    children: [
      {
        path: ":cid/:aid",
        component: AudioPlayer,
      },
      {
        path: ":cid/:aid/:id",
        component: AudioPlayer,
      },
    ],
  },
  {
    path: '/book',
    name: 'Book',
    component: Book,
    children: [
      {
        path: ":id",
        component: Book,
      },
    ],
  },
  {
    path: '/view',
    name: 'View',
    component: View,
    children: [
      {
        path: ":model/:id",
        component: View,
      },
    ],
  },
  {
    path: '/pureland',
    name: 'Pureland',
    component: Pureland,
    children: [
      {
        path: ":id",
        name: 'Pureland',
        component: Pureland,
      },
    ],
  },
  {
    path: '/master',
    name: 'Master',
    component: Master,
    children: [
      {
        path: ":id",
        name: 'Master',
        component: Master,
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/aview/:id',
    name: 'AboutView',
    component: AboutView,
  }, 
  {
    path: '/announce',
    name: 'Announce',
    component: Announce,
    children: [
      {
        path: ":id",
        name: 'Announce',
        component: Announce,
      },
    ],
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    children: [
      {
        path: ":model",
        name: 'Search',
        component: Search,
      },
    ],
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: Feedback,
  },
  {
    path: '/u',
    name: 'Redirect',
    component: () => import('../views/redirect/index'),
    children: [
      {
        path: ":id",
        component: () => import('../views/redirect/index'),
      },
    ],
  },
  {
    path: '/qunshu',
    name: 'Qunshu',
    component: Qunshu,
  },
  {
    path: '/sp/:id',
    name: 'Special',
    component: Special,
  },
  {
    path: '/ap/:id',
    name: 'SVideo',
    component: SVideo,
  },
  {
    path: '/sp',
    name: 'Special',
    component: Special,
  },
  {
    path: '/sp/live',
    name: 'SLive',
    component: SLive,
  },
  {
    path: '/p/live',
    name: 'Pureland',
    component:   PurelandLive,
  },
  {
    path: '/sp/down',
    name: 'SDownload',
    component: SDownload,
  },
  {
    path: '/sp/shi',
    name: 'Birthday',
    component: Birthday,
  },
  {
    path: '/sp/vt',
    name: 'SLiveVt',
    component: SLiveVt,
  },
  {
    path: '/dy/:id',
    name: 'Daoying',
    component: Daoying,
  },
  {
    path: '/cg/:id',
    name: 'Ciguang',
    component: Ciguang,
  },
  {
    path: "/ciguang/:id",
    name: 'CiguangProduct',
    component: () => import('../views/Special/CiguangProduct'),
  },
  { 
    path: '*', 
    component: NotFound 
  }
]
/**
 * 重写路由的push方法
 */
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((error) => error);
};

const router = new VueRouter({
  routes,
  mode: "history",
})

export default router
