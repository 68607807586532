import Vue from 'vue'
import App from './App.vue'
import VueAxios from "vue-axios";
import Meta from "vue-meta";
import router from './router'
import store from './store'
import axios from "axios";
import tools from './common/tools';
import "./assets/font/iconfont.css";
import "./assets/css/public.css";
import Cookies from 'js-cookie'
import VueClipboard from 'vue-clipboard2' 
import { Tabbar, TabbarItem, ImagePreview, Image as VanImage, ShareSheet, Form, Uploader, Empty, Field, Badge, Toast, Button, Divider, NoticeBar, NavBar, Search, Icon, Swipe, SwipeItem, Lazyload, Grid, GridItem, List, Cell, CellGroup, Col, Row, Tab, Tabs, PullRefresh, Loading, Card, Radio, RadioGroup, Picker, Popup, Stepper } from 'vant';

Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NavBar);
Vue.use(Search);
Vue.use(Icon);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Col);
Vue.use(Row);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(PullRefresh);
Vue.use(Loading);
Vue.use(ImagePreview);
Vue.use(VanImage);
Vue.use(Divider);
Vue.use(NoticeBar);
Vue.use(ShareSheet);
Vue.use(Toast);
Vue.use(Button);
Vue.use(Badge);
Vue.use(Form);
Vue.use(Field);
Vue.use(Uploader);
Vue.use(Empty);
Vue.use(Card);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Stepper);
Vue.use(Cookies);

Vue.use(VueAxios, axios);
VueClipboard.config.autoSetContainer = true // add this line
Vue.use(VueClipboard);
Vue.use(Meta);
Vue.config.productionTip = false
Vue.prototype.$tools = tools;


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

