<template>
  <div class="view">
    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onBack">
      <template #right>
        <van-icon name="search" size="18" @click="onSearch"/>
      </template>
    </van-nav-bar>

    <div class="c-view">
      <div class="c-header">
        <div class="c-title">{{ info.title }}</div>
        <van-divider />
        <div class="c-desc">{{ info.date }}</div>
      </div>
      <div class="content" v-html="info.content"></div>
    </div>

  </div>
</template>
<script>

export default {
  data() {
    return {
      title: '公 告',
      info: [],
      model: 'c',
    }
  },
  methods: {
    //获取欄目列表
    getInfo(id){
      this.axios.get(this.$tools.api + '/index/getaview/id/' + id)
      .then(response => {
        if(response.data.code==1){
          this.info = response.data.info;
          this.info.content = this.$tools.fromatContent(this.info.content);
        }
      }) 
    },
 
    //返回
    onBack(){
      this.$router.push({ path: `/` })
    },
    onSearch(){
      console.log('搜索')
      this.$router.push({ path: `/search/announce` }) // -> /user/123
    }
  },
  beforeDestroy() {

  },
  mounted(){

  },
  created() {
    this.getInfo(this.$route.params.id)
  },
  metaInfo () {
    return {
      title: this.info.title + '-' + this.title
    }
  },
};
</script>
<style  scoped>
.view{
    background-color: #f7f8fa;
}
.c-header{
  margin:10px 10px 5px 10px;
  background-color: #fff;
}
.c-desc{
  font-size:14px;
  text-align: center;
  padding:0px 0 10px 0;
  color:#808080;
}
.c-title{
  padding:20px 10px 0 10px;
  line-height: 30px;
  font-size:18px;
  text-align: center;
}
.content{
  margin:0px 10px 50px 10px;
  padding:20px;
  line-height: 30px;
  color:#323233;
    background-color: #fff;
}
</style>