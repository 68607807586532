<template>
  <div class="view">
    <div class="bg" v-show="isShow">
      <van-image
          :src="downBg"
          @click = "onBack"
        />
    </div>
    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onBack">
    </van-nav-bar>
    <van-row class="card-list"
      v-for="item in list" 
      :key="item"
    >
      <van-col span="10">
        <van-image
          :src="item.img"
        />
      </van-col>
      <van-col span="12" offset="2">
        <div class="card-button">
          <van-divider
              :style="{color: '#a93623', borderColor: '#a93623', padding: '10px 16px' }"
            >
              {{ item.title }}
            </van-divider>
            <div class="tb-10">
              <van-button icon="down" type="default" block @click="handleDown(item.down1)">中快速度</van-button>
            </div>
            <div class="tb-10">
              <van-button icon="down" type="default" block @click="handleDown(item.down2)">中慢速度</van-button>
            </div>          
        </div>
      </van-col>
    </van-row>
  </div>
</template>
<script>

export default {
  data() {
    return {
      title: '百日共修佛號下載',
      info: [],
      isShow: false,
      downBg: this.$tools.static_url +"sandbox_v2/img/pureland/weixin-down-bg.png",
      fullHeight: document.documentElement.clientHeight - 380,
      imgHeight: document.documentElement.clientHeight - 200,
      color: '#ffefd6',
      list : [
        {
          'title' : '金色佛像',
          'img' : this.$tools.static_url +"sandbox_v2/img/pureland/amtf-jinse-mobile.png",
          'down1' : this.$tools.getVideoNumUrl(this.$tools.cz_media+'mp4/', 'PC-019-0001', 'mp4'),
          'down2' : this.$tools.getVideoNumUrl(this.$tools.cz_media+'mp4/', 'PC-020-0001', 'mp4'),
        },
        {
          'title' : '白色佛像',
          'img' : this.$tools.static_url +"sandbox_v2/img/pureland/amtf-baise-mobile.png",
          'down1' : this.$tools.getVideoNumUrl(this.$tools.cz_media+'mp4/', 'PC-019-0003', 'mp4'),
          'down2' : this.$tools.getVideoNumUrl(this.$tools.cz_media+'mp4/', 'PC-020-0003', 'mp4'),
        },
        {
          'title' : '彩色佛像',
          'img' : this.$tools.static_url +"sandbox_v2/img/pureland/amtf-caise-mobile.png",
          'down1' : this.$tools.getVideoNumUrl(this.$tools.cz_media+'mp4/', 'PC-019-0004', 'mp4'),
          'down2' : this.$tools.getVideoNumUrl(this.$tools.cz_media+'mp4/', 'PC-020-0004', 'mp4'),
        },
        {
          'title' : 'MP3下載',
          'img' : this.$tools.static_url +"sandbox_v2/img/pureland/amtf-audio-mobile.png",
          'down1' : this.$tools.getVideoNumUrl(this.$tools.cz_media+'mp3/', 'PC-019-0001', 'mp3'),
          'down2' : this.$tools.getVideoNumUrl(this.$tools.cz_media+'mp3/', 'PC-020-0001', 'mp3'),
        },
      ],
    }
  },
  methods: {
    handleDown(url){
      window.open(url);
    },
    //返回
    onBack(){
      this.isShow = false;
      //this.$router.push({ path: `/sp/live` })  
    },
  },
  beforeDestroy() {

  },
  mounted(){

  },
  created() {
    if(this.$tools.IsWeixinOrAlipay()==13){
      this.isShow = true;
    }
  },
  metaInfo () {
    return {
      title: this.title
    }
  },
};
</script>
<style  scoped>
.view{
    background-color: #f7f8fa;
    margin-bottom: 70px;
    
}
.c-content{
  padding:5px 5px;
}
.card-list{
  background-color: #f9f1e6;
  padding:20px;
  margin:15px 10px;
}
.card-button{
  display: block;
  padding:10px
}
.tb-10{
  padding:10px 0;
}

.bg {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.9);
    text-align: end;
    overflow: hidden;
    padding: 1rem 5px 0 1rem;
}

.bg img {
    width: 93%;
    height: auto;
}
</style>