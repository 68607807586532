<template>
  <div class="view">
    <van-nav-bar :title="info.title" left-text="返回" left-arrow @click-left="onBack">
    </van-nav-bar>

    <div class="c-view">
      <div class="c-header">
        <div class="c-title">{{ info.titleintact}}</div>
        <van-divider />
        <div class="c-desc">{{ info.author }} | {{ info.date }}</div>
      </div>
      <div class="content" v-html="info.content"></div>
    </div>

  </div>
</template>
<script>

export default {
  data() {
    return {
      title: '文字',
      info: [],
      model: 'c',
    }
  },
  methods: {
    //获取欄目列表
    getInfo(id){
      this.axios.get(this.$tools.api + '/book/article/id/' + id)
      .then(response => {
        if(response.data.code==1){
          this.info = response.data.info;
          this.info.content = this.fromatContent(this.info.content);
        }
      }) 
    },
 
    //返回
    onBack(){
      if(this.model == 'a'){
        this.$router.push({ path: `/pureland` })
      }
      if(this.model == 'b'){
        this.$router.push({ path: `/master` })
      }
      if(this.model == 'c'){
        this.$router.push({ path: `/book` })
      }   
      if(this.model == 'd'){
        this.$router.push({ path: `/` })
      }  
      if(this.model == 'e'){
        this.$router.push({ path: `/announce` })
      } 
    },

    fromatContent(html){
      return this.$tools.fromatContent(html);
    }
  },
  beforeDestroy() {

  },
  mounted(){

  },
  created() {
    this.model = this.$route.params.model;
    this.getInfo(this.$route.params.id)
  },
  metaInfo () {
    return {
      title: this.info.title
    }
  },
};
</script>
<style  scoped>
.view{
    background-color: #f7f8fa;
}
.c-header{
  margin:10px 10px 5px 10px;
  background-color: #fff;
}
.c-desc{
  font-size:14px;
  text-align: center;
  padding:0px 0 10px 0;
  color:#808080;
}
.c-title{
  padding:20px 10px 0 10px;
  line-height: 30px;
  font-size:18px;
  text-align: center;
}
.content{
  margin:0px 10px 50px 10px;
  padding:20px;
  line-height: 30px;
  color:#323233;
  background-color: #fff;
}
.content img{
  width:100%;
}
</style>