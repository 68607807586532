<template>
  <div class="view">
    <van-row>
      <van-col span="24" class="video-title">
        <div>群書只要續篇，敬請期待！</div>
      </van-col>
    </van-row>
  </div>
</template>
<script>

export default {
  data() {
    return {
      title: '群書只要續篇，敬請期待！',
    }
  },
  methods: {
  },
  beforeDestroy() {

  },
  mounted(){

  },
  created() {

  },
  metaInfo () {
    return {
      title: this.title
    }
  },
};
</script>
<style  scoped>
.view{
    background-color: #f7f8fa;
    margin-bottom: 70px;  
}
.c-content{
  padding:5px 5px;
}
.video-title{
  background-color: #fff;
  text-align:center;
  font-size:18px;
  padding:15px;
  margin-bottom:15px;
}
</style>