<template>
  <div class="view">
    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onBack">
    </van-nav-bar>

    <div class="c-view">
      <div
      class="show"
      v-for="(item, index) in info" 
      :key="index"
      >
        <div class="show-title">{{ item.data.title[0].cn }} <br/>{{ item.data.title[0].en }}</div>
        <div
        class="show-content"
        v-for="(s_item, index) in item.data.content" 
        :key="index"
        >
          <div class="show-content-k" v-html="s_item.k"></div>
          <div class="show-content-v" v-html="s_item.v"></div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

export default {
  data() {
    return {
      title: '內容',
      info: [],
      json:[
        1,{'title':'捐款賬戶'},
        2,{'title':'聯繫我們'}
      ]
    }
  },
  methods: {
    //获取欄目列表
    getInfo(id){
      this.axios.get(this.$tools.api + '/pureland/getpage/id/' + id)
      .then(response => {
          this.info = response.data;
      }) 
      this.title = this.json[id].title;
    },
 
    //返回
    onBack(){
      this.$router.push({ path: `/about` })  
    },
  },
  beforeDestroy() {

  },
  mounted(){

  },
  created() {
    this.getInfo(this.$route.params.id)
  },
  metaInfo () {
    return {
      title: this.title
    }
  },
};
</script>
<style  scoped>
.view{
    background-color: #f7f8fa;
    margin-bottom: 70px;
}
.c-view{
  margin-top:20px;
}
.show{
  margin:0 10px 20px 10px;
}
.show-title{
  line-height: 25px;
  padding:15px 0px;
  text-align: center;
  color: #fff;
  background-color: #38f;
}
.show-content{
  background-color: #fff;
  margin-bottom: 1px;
}
.show-content-k{
  font-size: 14px;
  text-align: center;
  color:#7e7e7e;
  padding:5px 0px;
}
.show-content-v{
  font-size: 14px;
  padding:10px 0px 15px 0px;
  text-align: center;
}
</style>