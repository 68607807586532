<template>
  <van-tabbar v-model="active" route class="jz-tabbar" @change="onChange">
    <van-tabbar-item name="Home"  replace to="/">
      <span class="iconfont icon-jz-home"></span>
      <span class="jz-tabbar-title">首頁</span>
    </van-tabbar-item>
    <van-tabbar-item name="Master" replace to="/master">
      <span class="iconfont icon-jz-laoshi"></span>
      <span class="jz-tabbar-title">學院導師</span>
    </van-tabbar-item>
    <van-tabbar-item name="Pureland" replace to="/pureland">
      <span class="iconfont icon-jz-xuexiao"></span>
      <span class="jz-tabbar-title">淨宗學院</span>
    </van-tabbar-item>
    <van-tabbar-item name="About" replace to="/about">
      <span class="iconfont icon-jz-about"></span>
      <span class="jz-tabbar-title">關於我們</span>
      </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      active: 'Home',
    };
  },
  methods: {
    onChange(index) {
      this.active = index;
    },
  }
};
</script>

<style  scoped>
.jz-tabbar .iconfont{
  display: block;
  text-align: center;  
  font-size: 22px;
  padding-bottom:5px;
}
.jz-tabbar-title{
  text-align: center;
  font-size: 12px;
}
</style>