<template>
  <div class="about">

    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onBack">
    </van-nav-bar>
    <van-empty :description="description">
    
    <van-button round type="danger" class="bottom-button" @click="onBack">回到首頁</van-button>
</van-empty>


  </div>

</template>
<script>

export default {
  data() {
    return {
      title: '頁面不存在',
      iTimer :null,
      description : '您訪問的頁面不存在，即將返回首頁!',
      onTime: 5,
    }
  },
  methods: { 
    //返回
    onBack(){
      this.$router.push({ path: `/` }) // -> /user/123
    },  
  },

  created() {
      this.iTimer = setInterval(()=>{
         this.onBack();
      },5000)
  },
  beforeDestroy() {
      clearInterval(this.iTimer);
  },
  metaInfo () {
    return {
      title: this.title
    }
  },
};
</script>
<style>
  .bottom-button {
    width: 160px;
    height: 40px;
  }
</style>