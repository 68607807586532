import { render, staticRenderFns } from "./JiangYan.vue?vue&type=template&id=2746dfda&scoped=true&"
import script from "./JiangYan.vue?vue&type=script&lang=js&"
export * from "./JiangYan.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2746dfda",
  null
  
)

export default component.exports