<template>
  <div class="about">

    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onBack">
      <template #right>
        <van-icon name="search" size="18" />
      </template>
    </van-nav-bar>
    <div class="margin-bottom-50"></div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="info.title"
        name="標題"
        label="標題"
        placeholder="標題"
        :rules="[{ required: true, message: '標題' }]"
      />
      <van-field
        v-model="info.name"
        name="姓名"
        label="姓名"
        placeholder="姓名"
      />
      <van-field
        v-model="info.email"
        name="郵箱"
        label="郵箱"
        placeholder="郵箱"
      />
      <van-field
        v-model="info.content"
        rows="3"
        autosize
        label="問題描述"
        type="textarea"
        maxlength="50"
        placeholder="请输入問題描述"
        show-word-limit
      />
      <van-field name="uploader" label="問題截圖">
        <template #input>
          <van-uploader v-model="uploader" :after-read="afterRead" :max-size="500 * 1024" @oversize="onOversize" :max-count="1"/>
        </template>
      </van-field>
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </div>

</template>
<script>
import qs from 'qs'

export default {
  data() {
    return {
      title: '問題反馈',
      info: {
        title: '',
        name: '',
        email: '',
        content: '',
        device: 'H5',
        image: '',
      },
      uploader: [],
    }
  },
  methods: {
    onSubmit(values) {
      
      this.axios({
        url: this.$tools.api_cg + 'user/addfeedback' + this.$tools.client_cg,
        method: 'post',
        data: this.info,
        transformRequest: [function (data) {
          data = qs.stringify(data);
          return data;
        }],
        headers:{'Content-Type':'application/x-www-form-urlencoded'}
      }).then(response => {         
          console.log(response.data);
          
      }).catch(function (error) {
          console.log(error);
      });
      console.log('submit', values);
    },
    // 返回布尔值
    beforeRead(file) {
      if (file.type !== 'image/jpeg' || file.type !== 'image/png') {
        this.$toast('請上傳jpg或png圖片');
        return false;
      }
      return true;
    },
    afterRead(file) {
      // 此时可以自行将文件上传至服务器
      this.info.image = file.content
    },
    onOversize() {
      this.$toast('圖片大小不能操作 500kb');
    },
    //返回
    onBack(){
      this.$router.push({ path: `/` }) // -> /user/123
    }, 
  },
  mounted() {
    this.info.device = this.$tools.getDevice();
  },
  created() {
    
  },
  metaInfo () {
    return {
      title: this.title
    }
  },
};
</script>
<style  scoped>
.college-logo{
  text-align: center;
  padding:70px 100px;
}
.college-logo img{
    width:100%;
}
</style>