<template>
  <div class="video">
    <van-nav-bar :title="title" left-text="返回" left-arrow @click-left="onBack">
      <template #right>
        <van-icon name="search" size="18" @click="onSearch"/>
      </template>
    </van-nav-bar>
    <van-tabs @click="getAlbum()" v-model="cid">
      <van-tab 
      v-for="item in clist" 
      :key="item.id"
      :title="item.title"
      :name="item.id"
      >
      <van-row class="v-list" gutter="15">
          <van-col span="12"
          v-for="(item, index) in alist" 
          :key="item.id"
          class="v-item"
          >
            <a @click="goto(index)">
            <img v-lazy="getImg320(item.cover)" alt="" class="v-item-img">
            <div class="v-item-desc">
              <div class="v-item-title">
                {{ item.title }}
              </div>
              <div class="v-item-date">{{ item.date }}</div>
            </div>
          </a>
        </van-col>
        </van-row>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>

export default {
  data() {
    return {
      title: '導師講演',
      clist: [],
      alist: [],
      cid: 0,
      activeName: 0,
      metaTitle: '',
    }
  },
  methods: {
    //获取欄目列表
    getCategory(id){
      this.axios.get(this.$tools.api + '/masterspeech/getcategory?id='+id)
      .then(response => {
        if(response.data.code==1){
          this.clist = response.data.rows;
          if(id == null){
            this.cid = this.clist[0].id;
          }else{
            this.cid = id;
          }
          this.getAlbum();
        }
      }) 
    },
    //获取專輯列表
    getAlbum(){
      this.$router.push({ path: `/masterspeech/${this.cid}` })
      this.axios.get(this.$tools.api + '/masterspeech/getalbum?id='+this.cid )
      .then(response => {
        if(response.data.code==1){
          this.alist = response.data.rows;
        }
      }) 
      this.metaTitle = this.$tools.getCategoryTitle(this.clist, this.cid);
    },
    goto(index){
      let info = this.alist[index];
      this.$router.push({ path: `/player/a/${info.id}/${info.cnum}` }) // -> /user/123
    },    
    //返回
    onBack(){
      this.$router.push({ path: `/` }) // -> /user/123
    },
    onSearch(){
      this.$router.push({ path: `/search/masterspeech` }) // -> /user/123
    },
    getImg320(url){
      return url + this.$tools.image_320;
    }
  },
  beforeDestroy() {

  },
  created() {
    console.log(this.$route.params.id);
    this.getCategory(this.$route.params.id);
  },
  metaInfo () {
    return {
      title: this.metaTitle + '-' + this.title
    }
  },
};
</script>
<style  scoped>

</style>